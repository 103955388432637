import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop.jsx";
import Layout from "./components/Layout";
import PageTracker from "./components/PageTracker.js";
import "./App.css";
import { setLanguage } from "./data/languageSlice.js";
import Home from "./pages/Home.jsx";
import ListingCruises from "./pages/booking-pages/ListingCruises.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import CruisesDetails from "./pages/booking-pages/CruisesDetails.jsx";
import Checkout from "./pages/booking-pages/Checkout.jsx";
import BookingConfirmation from "./pages/BookingConfirmation.jsx";
import AddExtra from "./pages/booking-pages/AddExtra.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import BecomePartner from "./pages/BecomePartner.jsx";
import HelpCenter from "./pages/HelpCenter.jsx";
import TermsAndConditions from "./pages/TermsAndConditions.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import HowWeWork from "./pages/HowWeWork.jsx";
// import Error from "./pages/Error.jsx";
import Signup from "./pages/Signup.jsx";
import Signin from "./pages/Signin.jsx";
// import Test from "./pages/Test.jsx";

import { fetchDurations } from "./data/durationsSlice.js";

// import "../node_modules/swiper/swiper-bundle.min.css";
// import CruiseBooking from "./components/cruiseBooking/CruiseBooking.jsx";
// import CabinBooking from "./components/cruiseBooking/CabinBooking.jsx";
// import InsertCstData from "./components/cruiseBooking/InsertCstData.jsx";
// import ThanksPage from "./components/cabinsBooking/ThanksPage.jsx";
// import BookingSearch from "./pages/BookingSearch.jsx";
// import AddExtra from "./components/cruiseBooking/AddExtra.jsx";
import FilterTypes from "./pages/filter-types/FilterTypes";
import Offers from "./pages/Offers.jsx";
import { Seat } from "./pages/Seat.jsx";

function App() {
  // useEffect(() => {

  //   const cursor = document.createElement("div");
  //   cursor.classList.add("custom-cursor");
  //   document.body.appendChild(cursor);

  //   function updateCursorPosition(e) {
  //     cursor.style.left = e.clientX + "px";
  //     cursor.style.top = e.clientY + "px";
  //   }

  //   // إضافة مستمع الحدث
  //   document.addEventListener("mousemove", updateCursorPosition);

  //   // تنظيف عند إزالة المكون
  //   return () => {
  //     document.removeEventListener("mousemove", updateCursorPosition);
  //     document.body.removeChild(cursor);
  //   };
  // }, []);
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("/en");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDurations());
  }, [dispatch]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments
    if (!window.location.href.includes("Sales-Blitz")) {
      if (pathSegments.length < 1) {
        const newPath = `/en${currentUrl.pathname}`;
        window.location.href = `${currentUrl.origin}${newPath}${currentUrl.search}`;
      } else {
        if (["en", "eg", "de", "ru"].includes(pathSegments[0])) {
          dispatch(setLanguage(pathSegments[0]));
          setBasename(`/${pathSegments[0]}`);
        } else {
          window.location.href = `${currentUrl.origin}/${language}${currentUrl.pathname}`;
        }
      }
    }
  }, [language, dispatch]);
  useEffect(() => {
    const head = document.head;
    const stylesheets = {
      eg: {
        id: "eg-stylesheet",
        href: "bootstrap-rtl.min.css",
      },
      ru: {
        id: "ru-stylesheet",
        href: "ru.css",
      },
    };

    // Remove all previously added stylesheets
    Object.values(stylesheets).forEach(({ id }) => {
      const existingLink = document.getElementById(id);
      if (existingLink) {
        head.removeChild(existingLink);
      }
    });
    if (stylesheets[language]) {
      const { id, href } = stylesheets[language];
      let link = document.createElement("link");
      link.id = id;
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = href;
      head.appendChild(link);
    }
  }, [language]);
  return (
    <HelmetProvider>
      {" "}
      <Router basename={basename}>
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route
              path="cruise-type/:cruiseTypeUrl"
              element={<FilterTypes />}
            />
            <Route path="booking-cruises" element={<ListingCruises />} />
            <Route path="seat" element={<Seat />} />
            <Route path="cruises-details" element={<CruisesDetails />} />
            <Route path="add-extra" element={<AddExtra />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="special-offers" element={<Offers />} />
            <Route
              path="booking-confirmation"
              element={<BookingConfirmation />}
            />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="become-partner" element={<BecomePartner />} />
            <Route path="help-center" element={<HelpCenter />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="how-we-work" element={<HowWeWork />} />
            <Route path="signup" element={<Signup />} />
            <Route path="signin" element={<Signin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>

          {/* <Route path="test" element={<Test />} />
          <Route path="test2" element={<MagazineViewer />} /> */}
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;

/*<Route path="booking-cruises" element={<CruiseBooking />} />
<Route path="booking-cabins" element={<CabinBooking />} />
<Route path="add-extra" element={<AddExtra />} />
<Route path="booking-information" element={<InsertCstData />} />
<Route path="booking-completed" element={<ThanksPage />} />*/
