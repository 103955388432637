import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

// Get initial state from localStorage if available

const initialState = {
  cruisesListBooking: [],
  loadingCruisesList: false,
  errorCruisesList: null, // Store the error message
};

export const fetchCruiseListBooking = createAsyncThunk(
  "cruisesListBooking/fetchCruiseListBooking",
  async ({
    payload,
    PageNumber,
    CruiseLocationId,
    CruiseTypeIds,
    CruiseBrandIds,
    CruiseRatingName,SearchInput
  }) => {
    // Store payload in localStorage before making the request
    // localStorage.setItem("lastSearchPayload", JSON.stringify(payload));

    const response = await axios.post(
      `${Url}/be/getCruiseList?CruiseLocationId=${CruiseLocationId}&CruiseBrandId=${CruiseBrandIds}&CruisesTypeId=${CruiseTypeIds}&RatingStarName=${CruiseRatingName}&Search=${SearchInput}&PageNumber=${PageNumber}`,
      payload
    );
    return { data: response.data, payload };
  }
);

// Add a new thunk to refetch using saved payload
// export const refetchCruiseList = createAsyncThunk(
//   "cruisesListBooking/refetchCruiseList",
//   async (PageNumber, { getState }) => {
//     const savedPayload = localStorage.getItem("lastSearchPayload");
//     if (!savedPayload) {
//       throw new Error("No previous search found");
//     }
//     const payload = JSON.parse(savedPayload);
//     const response = await axios.post(
//       `${Url}/be/getCruiseList?PageNumber=${PageNumber}&`,
//       payload
//     );
//     return { data: response.data, payload };
//   }
// );

const cruiseListSlice = createSlice({
  name: "cruisesListBooking",
  initialState,
  reducers: {
    clearCruisesList: (state) => {
      state.cruisesListBooking = [];
      state.errorCruisesList = null;
      state.lastSearchPayload = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseListBooking.pending, (state) => {
        state.loadingCruisesList = true;
      })
      .addCase(fetchCruiseListBooking.fulfilled, (state, action) => {
        state.loadingCruisesList = false;
        state.cruisesListBooking = action.payload.data;
        state.errorCruisesList = null;
      })
      .addCase(fetchCruiseListBooking.rejected, (state, action) => {
        state.loadingCruisesList = false;
        state.errorCruisesList = action.error.message;
      });
    // Add cases for refetchCruiseList
    // .addCase(refetchCruiseList.pending, (state) => {
    //   state.loadingCruisesList = true;
    //   state.errorCruisesList = null;
    // })
    // .addCase(refetchCruiseList.fulfilled, (state, action) => {
    //   state.loadingCruisesList = false;
    //   state.cruisesListBooking = action.payload.data;
    //   state.lastSearchPayload = action.payload.payload;
    //   state.errorCruisesList = null;
    // })
    // .addCase(refetchCruiseList.rejected, (state, action) => {
    //   state.loadingCruisesList = false;
    //   state.errorCruisesList = action.error.message;
    // });
  },
});

export const { clearCruisesList } = cruiseListSlice.actions;
export default cruiseListSlice.reducer;
