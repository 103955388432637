// itinerarySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "./url";

export const fetchDurationsPerCruise = createAsyncThunk(
  "durations/fetchDurationsPerCruise",
  async (cruiseId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.nilecabins.info/ws/getDurationsPerCruise?CruiseId=${cruiseId}`
      );
      return response.data; // Assuming the API response format is { data: [...] }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const durationsPerCruiseSlice = createSlice({
  name: "durationsPerCruise",
  initialState: {
    durationsPerCruise: [],
    loading: false, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDurationsPerCruise.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDurationsPerCruise.fulfilled, (state, action) => {
        state.loading = false;
        state.durationsPerCruise = action.payload;
      })
      .addCase(fetchDurationsPerCruise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default durationsPerCruiseSlice.reducer;
