import React, { Fragment, useEffect, useState } from "react";
import {
  Outlet,
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Zoom } from "react-awesome-reveal";

import { useDispatch, useSelector } from "react-redux";
import { error } from "jquery";
import { Alert, Snackbar, Tooltip, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobeAfrica,
  faHeadset,
  faHome,
  faLocationDot,
  faMapLocationDot,
  faMoneyBill,
  faPhone,
  faQuestionCircle,
  faRoute,
  faSignsPost,
  faStar,
  faUser,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { setLanguage } from "../data/languageSlice";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faSquareInstagram,
  faSquareXTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { fetchLayout } from "../data/layoutSlice";

const Layout = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { layoutData, loading, error } = useSelector(
    (state) => state.layoutData
  );
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    dispatch(fetchLayout());
  }, [dispatch]);

  useEffect(() => {
    const checkScroll = () => {
      // Convert 70vh to pixels
      const triggerHeight = window.innerHeight * 0.2;
      const triggerHeight2 = window.innerHeight * 0.1;

      if (window.scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.scrollY > triggerHeight2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);
  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(false);
  const [view3, setView3] = useState(false);
  // Function to handle the scroll to top action
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const { loadingNewsletter, errorNewsletter } = useSelector(
  //   (state) => state.newsLetter
  // );
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu open and closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [email, setEmail] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "ar" : "en";
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    // Check if the window's scroll position is greater than a certain value (e.g., 50px)
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [viewCurrency, setViewCurrency] = useState(true);
  const [viewLanguage, setViewLanguage] = useState(true);

  // mobile menu
  const [isMenuActive, setIsMenuActive] = useState(false);

  // Toggle menu handler
  const handleMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };
  const [isDestinationsActive, setIsDestinationsActive] = useState(false);

  const handleDestinationsClick = (e) => {
    e.preventDefault();
    setIsDestinationsActive(!isDestinationsActive);
  };
  const [activeTab, setActiveTab] = useState(1); // Default to the first tab

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <>
      {" "}
      <section className="header-banner py-5 bg-blue-1 z-9">
        <div className="container">
          <div className="row items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-15 y-gap-15 items-center">
                <div className="col-auto md:d-none">
                  <Link to="tel:+201000972210" className="text-12 text-white">
                    <FontAwesomeIcon icon={faPhone} /> +(20) 10 00972210{" "}
                  </Link>
                </div>

                <div className="col-auto md:d-none">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>

                <div className="col-auto">
                  <Link
                    to="mailto:info@bookingnile.com"
                    className="text-12 text-white"
                  >
                    <FontAwesomeIcon icon={faEnvelope} /> info@bookingnile.com{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row x-gap-15 items-center jusify-between">
                <div className="col-auto">
                  <div className="row x-gap-20 items-center ">
                    <div className="col-auto md:d-none">
                      <Link to="/" className="text-12 text-white">
                        <Tooltip title="Home">
                          {" "}
                          <FontAwesomeIcon icon={faHome} className="me-1" />
                        </Tooltip>
                      </Link>
                    </div>
                    <div className="col-auto  md:d-none">
                      <div className="w-1 h-20 bg-white-20"></div>
                    </div>
                    <div className="col-auto   ">
                      <button
                        className="d-flex items-center text-14 text-white"
                        data-x-click="currency"
                        onClick={() => setViewCurrency(!viewCurrency)}
                      >
                        <span className="js-currencyMenu-mainTitle">
                          <FontAwesomeIcon icon={faMoneyBill} /> USD{" "}
                        </span>
                        <i className="icon-chevron-sm-down text-7 ml-10"></i>
                      </button>
                    </div>

                    <div className="col-auto  md:d-none">
                      <div className="w-1 h-20 bg-white-20"></div>
                    </div>

                    <div className="col-auto  md:d-none">
                      <button
                        className="d-flex items-center text-14 text-white"
                        onClick={() => setViewLanguage(!viewLanguage)}
                      >
                        <img
                          src="/titsolutions/img/general/lang.png"
                          width="16"
                          height="16"
                          loading="lazy"
                          alt="Language"
                          className="rounded-full mr-10"
                        />
                        <span className="js-language-mainTitle">EN </span>
                        <i className="icon-chevron-sm-down text-7 ml-15"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-auto  md:d-none">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>
                <div className="col-auto md:d-none">
                  <Link to="/become-partner" className="text-12 text-white">
                    <Tooltip title="Become Partner">
                      <FontAwesomeIcon icon={faStar} />
                    </Tooltip>
                  </Link>
                </div>{" "}
                <div className="col-auto  ">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>
                <div className="col-auto">
                  <Link to="/contact-us" className="text-12 text-white">
                    <Tooltip title="Contact Us">
                      <FontAwesomeIcon icon={faHeadset} />
                    </Tooltip>
                  </Link>
                </div>{" "}
                <div className="col-auto ">
                  <div className="w-1 h-20 bg-white-20"></div>
                </div>
                <div className="col-auto">
                  <Link to="/signup" className="text-12 text-white">
                    <Tooltip title="Sign In / Sign Up">
                      {" "}
                      <FontAwesomeIcon icon={faUser} />{" "}
                    </Tooltip>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`currencyMenu  js-currencyMenu ${
          viewCurrency && "is-hidden"
        }`}
      >
        <div
          className="currencyMenu__bg"
          data-x-click="currency"
          onClick={() => setViewCurrency(true)}
        ></div>

        <div className="currencyMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your currency </div>
            <button className="pointer" onClick={() => setViewCurrency(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  United States dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">USD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Australian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">AUD </span>- $
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Brazilian real{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BRL </span>- R$
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Bulgarian lev{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">BGN </span>- лв.
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Canadian dollar{" "}
                </div>
                <div className="text-14 lh-15 mt-5">
                  <span className="js-title">CAD </span>- $
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`langMenu  js-langMenu ${viewLanguage && "is-hidden"}`}>
        <div
          className="langMenu__bg"
          onClick={() => setViewLanguage(true)}
        ></div>

        <div className="langMenu__content bg-white rounded-4">
          <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
            <div className="text-20 fw-500 lh-15">Select your language </div>
            <button className="pointer" onClick={() => setViewLanguage(true)}>
              <i className="icon-close"></i>
            </button>
          </div>

          <div className="modalGrid px-30 py-30 sm:px-15 sm:py-15">
            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">English </div>
                <div className="text-14 lh-15 mt-5 js-title">
                  United States{" "}
                </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Türkçe </div>
                <div className="text-14 lh-15 mt-5 js-title">Turkey </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">Español </div>
                <div className="text-14 lh-15 mt-5 js-title">España </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Français{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">France </div>
              </div>
            </div>

            <div className="modalGrid__item js-item">
              <div className="py-10 px-15 sm:px-5 sm:py-5">
                <div className="text-15 lh-15 fw-500 text-dark-1">
                  Italiano{" "}
                </div>
                <div className="text-14 lh-15 mt-5 js-title">Italia </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className={`header mt-40 -type-9 js-header ${
          isSticky ? "bg-dark-1 is-sticky" : ""
        }`}
      >
        <div className="header__container container">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <Link
                  to="/"
                  className="header-logo mr-20"
                  data-x="header-logo"
                  data-x-toggle="is-logo-dark"
                >
                  {window.location.pathname.includes("/booking") ||
                  window.location.pathname.includes("/contact-us") ||
                  window.location.pathname.includes("/checkout") ||
                  window.location.pathname.includes("/add-extra") ||
                  window.location.pathname.includes("/help-center") ||
                  window.location.pathname.includes("/signup") ||
                  window.location.pathname.includes("/signin") ||
                  window.location.pathname.includes("/cruise-type") ||
                  window.location.pathname.includes("/cruises-details") ? (
                    <img src="bookingnile.png" alt="logo icon" />
                  ) : (
                    <img src="bookingnilewhite.png" alt="logo icon" />
                  )}
                </Link>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div
                  className={`header-menu ${
                    isMenuActive ? "is-menu-active" : ""
                  }`}
                  data-x="mobile-menu"
                >
                  <div className="mobile-overlay"></div>

                  <div className="header-menu__content">
                    <div className="mobile-bg js-mobile-bg"></div>

                    <div className="menu js-navList">
                      <ul className="menu__nav text-white -is-active">
                        {/* <li>
                          <Link to="/sightseeing">
                            {" "}
                            <FontAwesomeIcon
                              icon={faSignsPost}
                              className="me-1"
                            />{" "}
                            Sightseeing
                          </Link>
                        </li> */}
                        {/* <li className="menu-item-has-children -has-mega-menu">
                          <Link to="#.">
                            <span className="mr-10">
                              {" "}
                              <FontAwesomeIcon
                                icon={faRoute}
                                className="me-1"
                              />{" "}
                              Destinations{" "}
                            </span>
                            <i className="icon icon-chevron-sm-down"></i>
                          </Link>

                          <div className="mega">
                            <div className="tabs -underline-2 js-tabs">
                              <div className="tabs__controls row x-gap-80 y-gap-10 lg:x-gap-20 pb-30 js-tabs-controls">
                                {[...Array(3)].map((_, index) => (
                                  <div className="col-auto" key={index}>
                                    <button
                                      className={`tabs__button text-light-1 fw-500 js-tabs-button ${
                                        activeTab === index + 1
                                          ? "is-tab-el-active"
                                          : ""
                                      }`}
                                      onClick={() => handleTabClick(index + 1)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faMapLocationDot}
                                        className="me-1"
                                      />{" "}
                                      {["Luxor", "Aswan", "Lake Nasser"][index]}
                                    </button>
                                  </div>
                                ))}
                              </div>

                              <div className="tabs__content js-tabs-content">
                                {activeTab === 1 && (
                                  <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                    <div className="mega__content">
                                      <div className="mega__grid">
                                        <div className="mega__item">
                                          
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Luxor 1 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Luxor 2 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Luxor 3 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Luxor 4 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Luxor 5 </Link>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mega__item">
                                           
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Luxor 6 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Luxor 7 </Link>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mega__item">
                                          
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Luxor 8 </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__image d-flex relative">
                                        <img
                                          src="TITSolutions/img/backgrounds/attractions.jpg"
                                          data-src="TITSolutions/img/backgrounds/attractions.jpg"
                                          alt=""
                                          className="rounded-4 js-lazy"
                                        />

                                        <div className="absolute w-full h-full px-30 py-24">
                                          <div className="text-22 fw-500 lh-15 text-white">
                                            Explore Luxor's <br /> Ancient
                                            Wonders
                                          </div>
                                          <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                            Discover Attractions
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {activeTab === 2 && (
                                  <div className="tabs__pane -tab-item-2 is-tab-el-active">
                                    <div className="mega__content">
                                      <div className="mega__grid">
                                        <div className="mega__item">
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Aswan 1 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Aswan 2 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Aswan 3 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Aswan 4 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Aswan 5 </Link>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mega__item">
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Aswan 6 </Link>
                                            </div>

                                            <div>
                                              <Link to="#.">Aswan 7 </Link>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mega__item">
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <Link to="#.">Aswan 8 </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__image d-flex relative">
                                        <img
                                          src="TITSolutions/img/backgrounds/attractions-aswan.jpg"
                                          data-src="TITSolutions/img/backgrounds/attractions-aswan.jpg"
                                          alt=""
                                          className="rounded-4 js-lazy"
                                        />

                                        <div className="absolute w-full h-full px-30 py-24">
                                          <div className="text-22 fw-500 lh-15 text-white">
                                            Explore Aswan's <br /> Ancient
                                            Wonders
                                          </div>
                                          <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                            Discover Attractions
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {activeTab === 3 && (
                                  <div className="tabs__pane -tab-item-3 is-tab-el-active">
                                    <div className="mega__content">
                                      <div className="mega__grid">
                                        <div className="mega__item">
                                          <div className="y-gap-5 text-15 pt-5">
                                            <div>
                                              <a href="hotel-list-1.html">
                                                Lake Nasser 1{" "}
                                              </a>
                                            </div>

                                            <div>
                                              <a href="hotel-list-2.html">
                                                Lake Nasser 2{" "}
                                              </a>
                                            </div>

                                            <div>
                                              <a href="hotel-half-map.html">
                                                Lake Nasser 3{" "}
                                              </a>
                                            </div>

                                            <div>
                                              <a href="hotel-grid-1.html">
                                                Lake Nasser 4{" "}
                                              </a>
                                            </div>

                                            <div>
                                              <a href="hotel-grid-2.html">
                                                Lake Nasser 5{" "}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mega__image d-flex relative">
                                        <img
                                          src="TITSolutions/img/backgrounds/attractions-Lake.jpg"
                                          data-src="TITSolutions/img/backgrounds/attractions-Lake.jpg"
                                          alt=""
                                          className="rounded-4 js-lazy"
                                        />

                                        <div className="absolute w-full h-full px-30 py-24">
                                          <div className="text-22 fw-500 lh-15 text-white">
                                            Explore the Wonders of Lake Nasser &
                                            Abu Simbel
                                          </div>
                                          <button className="button h-50 px-30 -blue-1 text-dark-1 bg-white mt-20">
                                            Discover Attractions
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li> */}
                        {loading ? (
                          <Fragment>
                            <li>
                              <Link to="#."> Nile Cruises</Link>
                            </li>
                            <li>
                              <Link to="#."> Lake Nasser Cruises</Link>
                            </li>
                            <li>
                              <Link to="#."> Dahabiyat</Link>
                            </li>
                          </Fragment>
                        ) : (
                          layoutData?.headerLayout?.cruisesTypes?.map(
                            (item) => (
                              <li
                                key={item.cruiseTypeUrl}
                                onClick={() => {
                                  handleMenuToggle();
                                  localStorage.removeItem(
                                    "selectedLocationName"
                                  );
                                }}
                              >
                                <Link to={`/cruise-type/${item.cruiseTypeUrl}`}>
                                  {" "}
                                  {item.cruiseTypeName}
                                </Link>
                              </li>
                            )
                          )
                        )}
                        <li onClick={handleMenuToggle}>
                          <Link to="/special-offers"> Special Offers</Link>
                        </li>{" "}
                        <li onClick={handleMenuToggle}>
                          <Link to="/about-us">
                            {" "}
                            {/* <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className="me-1"
                            />{" "} */}
                            About Us
                          </Link>
                        </li>{" "}
                      </ul>
                    </div>

                    <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer"></div>
                  </div>
                </div>

                {/* <div className="d-flex items-center ml-24 is-menu-opened-hide md:d-none">
                  <a
                    href="#"
                    className="text-26 xl:text-20 text-white icon-search"
                  ></a>
                </div> */}

                <div
                  className={`d-none xl:d-flex x-gap-20 items-center pl-20  ${
                    window.location.pathname.includes("/booking") ||
                    window.location.pathname.includes("/contact-us") ||
                    window.location.pathname.includes("/checkout") ||
                    window.location.pathname.includes("/add-extra") ||
                    window.location.pathname.includes("/help-center") ||
                    window.location.pathname.includes("/signup") ||
                    window.location.pathname.includes("/signin") ||
                    window.location.pathname.includes("/cruise-type") ||
                    window.location.pathname.includes("/cruises-details")
                      ? "text-primary"
                      : "text-white"
                  }`}
                  data-x="header-mobile-icons"
                  data-x-toggle="text-white"
                >
                  <div>
                    <div className="col-auto  ">
                      <button
                        className="d-none xl:d-flex items-center text-14  "
                        onClick={() => setViewLanguage(!viewLanguage)}
                      >
                        <img
                          src="/titsolutions/img/general/lang.png"
                          width="16"
                          height="16"
                          loading="lazy"
                          alt="Language"
                          className="rounded-full mr-10"
                        />
                        <span className="js-language-mainTitle">EN </span>
                        <i className="icon-chevron-sm-down text-7 ml-15"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    {!isMenuActive ? (
                      <button
                        onClick={handleMenuToggle}
                        className="d-flex items-center icon-menu text-inherit text-20"
                        data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"
                      ></button>
                    ) : (
                      <Button variant="danger">
                        <FontAwesomeIcon
                          className="d-flex items-center p-1 text-light  text-14"
                          icon={faX}
                          onClick={handleMenuToggle}
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
      <section
        className="layout-pt-md layout-pb-md bg-blue-1 newsletter  "
        style={{ position: "relative", overflow: "hidden" }}
      >
        <div className="container">
          <div className="row y-gap-30 justify-between items-center ">
            <div className="col-auto">
              <div className="d-flex y-gap-20 flex-wrap items-center">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>

                <div className="ml-30">
                  <h4 className="text-26 text-white fw-600">
                    Your BookingNile.com Adventure Awaits!
                  </h4>
                  <div className="text-white">
                    Sign up and we'll sail you to the best deals on
                    unforgettable cruises!
                  </div>
                </div>
              </div>
            </div>

            <form
              onSubmit={handleSubmit}
              className="col-auto"
              style={{ zIndex: 1 }}
            >
              <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
                <div>
                  <input
                    className="bg-white h-60"
                    type="text"
                    placeholder="Your Email"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    on
                    className="button -md h-60 bg-yellow-1 text-dark-1"
                  >
                    Subscribe{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="  effect-cls">
          <div
            className="cloud bg-size"
            style={{
              backgroundImage: `url(cloud-real.png)`,
              backgroundSize: "100% 180%",
              backgroundPosition: "center center",
              backgroundRepeat: "space",
              display: "block",
              animation: "moveClouds 60s linear infinite",
              animationDelay: "0s",
              position: "absolute",
              top: 0,
              height: "100%",
            }}
          >
            <img src="cloud-real.png" alt="" className="bg-img" />
          </div>
        </div>
        <style>
          {`
          @keyframes moveClouds {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
        `}
        </style>
      </section>
      <footer
        className="footer -type-1 text-white bg-blue-1"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            right: "0",
            top: "10%",
            height: "100%",
            width: "25%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        />
        {/* <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            right: "55%",
            top: "53%",
            height: "auto",
            width: "10%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        /> */}
        {/* <img
          src="footer-bg.webp"
          alt=""
          style={{
            position: "absolute",
            left: "0%",
            top: "53%",
            height: "auto",
            width: "10%",
            opacity: "0.8",
            transform: "rotate(4deg)",
          }}
        /> */}
        <div className="container">
          <div className="pt-60 pb-60">
            <div className="row y-gap-40 justify-between xl:justify-start">
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">About Booking Nile </h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="/about-us">About Us </Link>
                  <Link to="/how-we-work">How We Work </Link>
                  <Link to="/cruise-type/nile_cruises">Nile Cruises </Link>
                  <Link to="/cruise-type/lake_nasser_cruises">
                    Lake Naser Cruises{" "}
                  </Link>
                  <Link to="/cruise-type/dabeyat">Dahabiyat </Link>
                  <Link to="#.">Blogs </Link>
                  {/* <Link to="#.">Press </Link>
                  <Link to="#.">Gift Cards </Link>
                  <Link to="#.">Magazine </Link> */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Partners</h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="/become-partner">Become Partner</Link>
                  <Link to="/signin">Extranet login</Link>
                  <Link to="/help-center">Partner help</Link>
                  <Link to="#.">List your property</Link>
                  <Link to="#.">Become an affiliate</Link>
                  {/* <Link to="#.">Flight finder </Link>
                  <Link to="#.">Holiday Rental </Link>
                  <Link to="#.">Travel Agents </Link> */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Support </h5>
                <div className="d-flex y-gap-10 flex-column">
                  <Link to="/contact-us">Contact Us</Link>
                  {/* <Link to="#.">Legal Notice </Link> */}
                  <Link to="privacy-policy">Privacy Policy </Link>
                  <Link to="/terms-and-conditions">Terms and Conditions </Link>
                  <Link to="/help-center">FAQ & Help Center </Link>
                </div>
              </div>

              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30">Contact Us </h5>

                <div className="mt-30 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <Link to="tel:+201000972210" className="text-18 fw-500 mt-5">
                    +(20) 10 00972210{" "}
                  </Link>
                </div>

                <div className="mt-10 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <Link
                    to="mailto:info@bookingnile.com"
                    className="text-18 fw-500 mt-5"
                  >
                    info@bookingnile.com{" "}
                  </Link>
                </div>
                <div className="mt-10 d-flex align-items-start">
                  <div className="text-18 fw-500 mt-5 me-1">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <Link
                    to="https://maps.app.goo.gl/soCip2vkRJM8wY5EA"
                    target="_blank"
                    className="text-14 fw-400 mt-5"
                  >
                    El-Nasr Street, Building #28. New Maadi. Cairo, Egypt
                  </Link>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <h5 className="text-16 fw-500 mb-30 ">
                  <span className="text-16 fw-500 mb-10">
                    Follow us on social media
                  </span>{" "}
                </h5>

                <div className=" mt-20 py-10 text-lg-left  ">
                  <div className="row y-gap-10 justify-content-lg-left">
                    <div className="d-flex x-gap-20 items-center justify-content-center">
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          className="text-16"
                        />
                      </Link>

                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className="text-16"
                        />
                      </Link>
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          className="text-16"
                        />
                      </Link>
                      <Link to="#.">
                        <FontAwesomeIcon
                          icon={faLinkedinIn}
                          className="text-16"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-20 px-0 border-top-white-15 last-footer">
            <div className="footer-bottom row align-items-center justify-content-between  ">
              <div className="col-lg-3">
                <div className="d-flex items-start">
                  © {currentYear} BookingNile.com | All rights reserved.
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex items-center justify-center">
                  <div className="logos">
                    <img
                      src="bookingnile.png"
                      className="me-2"
                      style={{ height: "auto", width: "150px" }}
                      alt=""
                    />
                    <img
                      className="me-2"
                      src="tit-logo.png"
                      alt="titsolutions logo"
                    />
                    <img
                      className="me-2"
                      src="be-closer.png"
                      alt="be-closer  logo"
                    />
                    <img src="nut-logo.png" alt="nut travel logo" />
                  </div>
                </div>
              </div>

              <div className="col-lg-3 ">
                <div className="text-right">
                  Powered By{" "}
                  <Link to="https://titegypt.com" target="_blank">
                    T.I.T Solutions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
