import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Url } from "../url";
import axios from "axios";

const initialState = {
  cruiseDetails: {},
  loading: false,
  error: null,
  selectedCruiseId: null,
};
// Original fetch thunk
export const fetchCruiseDetails = createAsyncThunk(
  "cruiseDetails/fetchDetails",
  async ({ operationID, payload }) => {
    const response = await axios.post(
      // Using axios for POST request
      `${Url}/be/getCruiseCabins?OperatinID=${operationID}`, // Corrected variable name
      payload
    );
    if (response.status < 200 || response.status >= 300) {
      // Check for successful response
      throw new Error("Failed to fetch cruise details");
    }
    return { data: response.data, payload };
  }
);

const cruiseDetailsSlice = createSlice({
  name: "cruiseDetails",
  initialState,
  reducers: {
    setSelectedCruiseId: (state, action) => {
      state.selectedCruiseId = action.payload;
      localStorage.setItem("selectedCruiseId", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseDetails = action.payload.data;
        // state.lastSearchPayload = action.payload.payload;
        state.error = null;
      })
      .addCase(fetchCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedCruiseId } = cruiseDetailsSlice.actions;
export default cruiseDetailsSlice.reducer;
