import React, { Fragment, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DatePicker, Drawer } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Alert,
  Snackbar,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import { fetchCruiseListBooking } from "../data/groupBooking/cruiseListSlice";
import Loader from "../Loader";
import { styled } from "@mui/material/styles";
export const BookingForm = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const isDesktop = useMediaQuery("(min-width:1024px)");

  // You can also use them in combination
  const isSmallScreen = useMediaQuery("(max-width:1023px)"); // Both mobile and tablet

  const cursorStyle = {
    cursor: `url('/ship-wheel-white.svg') 25 25, auto`, // 25 25 represents the cursor hotspot position
  };
  const navigate = useNavigate();
  const { durations, loading, error } = useSelector((state) => state.durations);
  const [selectedDurationId, setSelectedDurationId] = useState(null);

  // Replace static locations with durations data
  const locations =
    durations?.map((duration) => ({
      name: duration.durationName,
      description: "Nile Cruise",
      dates: duration.listDates,
      durationId: duration.durationID, // Add this line
    })) || [];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]); // Add this to track available dates
  // Update handleLocationSelect to also set available dates
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name);
    setSelectedDurationId(location.durationId); // Add this line

    // Convert all dates to Date objects and sort them
    const availableDates = location.dates
      .map((date) => new Date(date.operationDate))
      .sort((a, b) => a - b);

    setSelectedDates(availableDates);

    // Find the first date that's after today
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const firstAvailableDate = availableDates.find((date) => date >= today);
    setSelectedDate(firstAvailableDate || null);

    setSearchTerm("");
    setIsActiveDestination(false);
  };
  const disabledDate = (date) => {
    const today = new Date();
    if (date < today.setHours(0, 0, 0, 0)) return true;

    // If no duration selected, disable all dates
    if (!selectedLocation) return true;

    // Check if date is in available dates
    return !selectedDates.some(
      (availableDate) => availableDate.toDateString() === date.toDateString()
    );
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dispatch = useDispatch();
  // const { cruisesListBooking, loadingCruisesList, errorCruisesList } =
  //   useSelector((state) => state.cruisesListBooking);

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [childrenAges, setChildrenAges] = useState([0]);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };
  // const handleChildIncrement = (setter) => {
  //   setter((prev) => (prev < 2 ? prev + 1 : prev));
  // };
  const handleChildIncrement = (setter) => {
    setter((prev) => {
      if (prev < 2) {
        // Add a new default age (0) when adding a child
        setChildrenAges((prevAges) => [...prevAges, 0]);
        return prev + 1;
      }
      return prev;
    });
  };

  // Update handleDecrement for children to also manage ages
  const handleChildDecrement = () => {
    if (children > 0) {
      setChildren((prev) => prev - 1);
      // Remove the last age when removing a child
      setChildrenAges((prevAges) => prevAges.slice(0, -1));
    }
  };
  // Add new function to handle age changes
  const handleAgeChange = (index, age) => {
    setChildrenAges((prevAges) => {
      const newAges = [...prevAges];
      newAges[index] = age;
      return newAges;
    });
  };
  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // 'error' | 'warning' | 'info' | 'success'
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSearch = async () => {
    setIsLoading(true);
    if (!selectedDurationId || !selectedDate) {
      setSnackbarMessage("Please select both duration and date");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
      setIsLoading(false);
      return;
    }
    const dateToFormat = new Date(selectedDate);
    if (isNaN(dateToFormat.getTime())) {
      setSnackbarMessage("Invalid date selected");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);

      return;
    }

    const formattedDate = format(dateToFormat, "yyyy/MM/dd");

    const payload = {
      durationID: selectedDurationId,
      opreationDate: formattedDate,
      numberRooms: rooms,
      listPassengersRoomsRequests: [
        {
          adultNumber: adults,
          childNumber: children,
          childAges: children > 0 ? childrenAges : [],
        },
      ],
    };
    // Create query string from payload
    const queryString = new URLSearchParams({
      durationID: payload.durationID,
      opreationDate: payload.opreationDate,
      numberRooms: payload.numberRooms,
      adultNumber: payload.listPassengersRoomsRequests[0].adultNumber,
      childNumber: payload.listPassengersRoomsRequests[0].childNumber,
      childAges: payload.listPassengersRoomsRequests[0].childAges.join(","), // Join array into a string
    }).toString();

    try {
      const resultAction = await dispatch(
        fetchCruiseListBooking({
          payload,
          PageNumber: 1,
          CruiseLocationId: null,
          CruiseTypeIds: null,
          CruiseBrandIds: null,
          CruiseRatingName: null,
          SearchInput: null,
        })
      );
      if (fetchCruiseListBooking.fulfilled.match(resultAction)) {
        // setSnackbarMessage(`Search On ${selectedLocation}...`);
        // setSnackbarSeverity("success");
        // setOpenSnackbar(true);
        localStorage.setItem("selectedLocationName", selectedLocation);
        setIsLoading(false);
        // setTimeout(() => navigate(`/booking-cruises?${queryString}`), 0);
        navigate(`/booking-cruises?${queryString}`);
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (err) {
      setSnackbarMessage(err.message || "An error occurred while searching");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#c80101",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));
  return (
    <div className="tabs -underline mt-60 js-tabs is-in-view">
      <div className="tabs__content mt-30 md:mt-20 js-tabs-content">
        <div className="tabs__pane -tab-item-1 is-tab-el-active">
          {!isMobile ? (
            <div className="mainSearch -w-1000 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
              <div className="button-grid items-center">
                <div
                  ref={searchMenuRef}
                  className={`searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch  ${
                    isActiveDestination ? "-is-dd-wrap-active" : ""
                  }`}
                  onClick={handleDestinationClick}
                >
                  <div
                    onBlur={handleBlur}
                    data-x-dd-click="searchMenu-loc"
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="text-15 fw-500 ls-2 lh-16">
                      Where would like to go?
                    </h4>
                    <div className="text-15 text-light-1 ls-2 lh-16 d-flex justify-content-between align-items-center">
                      <input
                        style={{ cursor: "pointer" }}
                        autoComplete="off"
                        type="search"
                        placeholder="Select Itinerary"
                        className="js-search js-dd-focus"
                        value={selectedLocation} // Set the input value to the selected location
                        onChange={(e) => {
                          setSelectedLocation(e.target.value);
                          setSearchTerm(e.target.value); // Update search term to filter results
                        }}
                      />{" "}
                      <FontAwesomeIcon
                        className="text-primary"
                        onClick={() =>
                          setIsActiveDestination(!isActiveDestination)
                        }
                        style={{ float: "right" }}
                        // className="mx-2 my-1"
                        icon={faAngleDown}
                      />
                    </div>
                  </div>

                  <div
                    className={`searchMenu-loc__field shadow-2 js-popup-window ${
                      isActiveDestination ? "-is-active" : ""
                    }`}
                    data-x-dd="searchMenu-loc"
                    data-x-dd-toggle="-is-active"
                  >
                    <div className="bg-white px-30 py-15 sm:px-0 sm:py-15 rounded-4">
                      <div className="y-gap-5 js-results">
                        {loading ? (
                          <Fragment>
                            <img
                              src="/ship-wheel.svg"
                              alt="Loading..."
                              className="spinning-wheel d-block m-auto"
                            />
                            <p className="text-center m-auto">Loading...</p>
                          </Fragment>
                        ) : filteredLocations.length > 0 ? (
                          filteredLocations.map((location, index) => (
                            <div
                              key={location.name}
                              style={{
                                margin: 0,
                                border: 0,
                                borderBottom:
                                  index + 1 != filteredLocations.length &&
                                  "1px solid #ddd",
                                padding: 0,
                              }}
                            >
                              <button
                                className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option"
                                onClick={() => handleLocationSelect(location)}
                              >
                                <div className="d-flex">
                                  <div className=" icon-destination text-light-1 text-20 pt-4"></div>

                                  <div className="ml-10">
                                    <div className="text-15 lh-12 fw-500">
                                      {location.name}
                                    </div>
                                    <div className="text-14 lh-12 text-light-1 mt-5">
                                      {/* {location.description} */}
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          ))
                        ) : (
                          <div className="text-center py-15">
                            No results found"
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="bg-white  py-1 "
                      style={{
                        borderBottom: "4px solid #1976d2",
                        height: "auto",

                        overflow: "hidden",
                      }}
                    >
                      {" "}
                      <Button
                        className="mx-auto   pt-2 text-center d-block"
                        onClick={() => setIsActiveDestination(false)}
                      >
                        <FontAwesomeIcon
                          className="mx-2 my-1"
                          icon={faAngleDoubleUp}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                  <div>
                    <LightTooltip
                      title={
                        selectedDate
                          ? null
                          : "Please select a duration to view available dates."
                      }
                    >
                      <h4 className="text-15 fw-500 ls-2 lh-16">
                        Departs as early as
                      </h4>
                      <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        placeholder={
                          selectedLocation
                            ? "Select Date"
                            : "Select duration first"
                        }
                        className="text-15 text-light-1 ls-2 lh-16"
                        disabledDate={disabledDate}
                        disabled={!selectedLocation}
                        renderValue={(value) => {
                          return format(value, "EEE, d MMM");
                        }}
                        oneTap
                      />{" "}
                    </LightTooltip>
                  </div>
                </div>
                <div
                  ref={GuestMenuRef}
                  className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                    isActiveGuest ? "-is-dd-wrap-active" : ""
                  }`}
                  onBlur={handleGuestBlur}
                >
                  <div
                    data-x-dd-click="searchMenu-guests"
                    onClick={handleGuestClick}
                  >
                    <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
                    <div
                      className="text-14 text-light-1 ls-2 lh-16 d-flex align-items-center justify-content-between"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        {" "}
                        <span className="js-count-adult">{adults}</span> adults
                        - <span className="js-count-child">{children}</span>{" "}
                        children -{" "}
                        <span className="js-count-room">{rooms}</span>{" "}
                        cabin/suite
                        {rooms > 1 ? "s" : ""}
                      </div>
                      <FontAwesomeIcon
                        className="text-primary"
                        onClick={() =>
                          setIsActiveDestination(!isActiveDestination)
                        }
                        style={{ float: "right" }}
                        // className="mx-2 my-1"
                        icon={faAngleDown}
                      />
                    </div>{" "}
                  </div>

                  <div
                    className={`searchMenu-guests__field shadow-2  ${
                      isActiveGuest ? "-is-active" : ""
                    }`}
                  >
                    {" "}
                    <div className="bg-white px-30 py-30 rounded-4">
                      <div className="row y-gap-10 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15 fw-500">Adults</div>
                          <div className="text-14 lh-12 text-light-1 mt-5">
                            Ages: +12
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="d-flex items-center js-counter">
                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                              onClick={() =>
                                adults > 1 && handleDecrement(setAdults)
                              }
                            >
                              <i className="icon-minus text-12"></i>
                            </button>

                            <div className="flex-center size-20 ml-15 mr-15">
                              <div className="text-15">{adults}</div>
                            </div>

                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                              onClick={() => handleIncrement(setAdults)}
                            >
                              <i className="icon-plus text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="border-top-light mt-24 mb-24"></div>

                      <div className="row y-gap-10 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15 lh-12 fw-500">Children</div>
                          <div className="text-14 lh-12 text-light-1 mt-5">
                            Ages: 2 to 11.99
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="d-flex items-center js-counter">
                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                              onClick={() => handleChildDecrement()}
                              disabled={children <= 0}
                            >
                              <i className="icon-minus text-12"></i>
                            </button>

                            <div className="flex-center size-20 ml-15 mr-15">
                              <div className="text-15">{children}</div>
                            </div>

                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                              onClick={() => handleChildIncrement(setChildren)}
                              disabled={children >= 2}
                            >
                              <i className="icon-plus text-12"></i>
                            </button>
                          </div>
                        </div>

                        {/* Add age selectors for children */}
                        {children > 0 && (
                          <div className="col-12 mt-10">
                            {childrenAges.map((age, index) => (
                              <div
                                key={index}
                                className="row y-gap-10 justify-between items-center mt-5"
                              >
                                <div className="col-auto">
                                  <div className="text-14 lh-12 text-light-1">
                                    Child {index + 1} age
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <select
                                    value={age}
                                    onChange={(e) =>
                                      handleAgeChange(
                                        index,
                                        parseInt(e.target.value)
                                      )
                                    }
                                    className="form-select text-primary text-14"
                                  >
                                    {[...Array(10)].map((_, i) => (
                                      <option key={i + 2} value={i + 2}>
                                        {i + 2} years
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      <div className="border-top-light mt-24 mb-24"></div>

                      <div className="row y-gap-10 justify-between items-center">
                        <div className="col-auto">
                          <div className="text-15 fw-500">Cabins / Suites</div>
                        </div>

                        <div className="col-auto">
                          <div className="d-flex items-center js-counter">
                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                              onClick={() =>
                                rooms > 1 && handleDecrement(setRooms)
                              }
                            >
                              <i className="icon-minus text-12"></i>
                            </button>

                            <div className="flex-center size-20 ml-15 mr-15">
                              <div className="text-15">{rooms}</div>
                            </div>

                            <button
                              className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                              onClick={() => handleIncrement(setRooms)}
                            >
                              <i className="icon-plus text-12"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="bg-white pb-1 "
                      style={{ borderBottom: "4px solid #1976d2" }}
                    >
                      {" "}
                      <Button
                        className="mx-auto  py-2 text-center d-block"
                        onClick={() => setIsActiveGuest(false)}
                      >
                        <FontAwesomeIcon
                          className="mx-2 my-1"
                          icon={faAngleDoubleUp}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="button-item">
                  <button
                    style={cursorStyle}
                    className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                    onClick={handleSearch}
                    disabled={isLoading}
                  >
                    <i className="icon-search text-20 mr-10"></i>
                    {isLoading ? "Loading..." : "Search"}
                  </button>
                </div>{" "}
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          ) : (
            <div className="col-12 booking-mobile">
              {" "}
              <Drawer
                anchor="bottom"
                placement="bottom"
                size="full"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                // PaperProps={{
                //   sx: {
                //     width: isMobile ? "100%" : "80%",

                //     padding: "20px",
                //   },
                // }}
              >
                <Drawer.Header className="ps-0  ">
                  <Drawer.Title className="ps-2   text-primary fw-bold">
                    <img
                      width={200}
                      src="bookingnile.png"
                      alt="logo"
                      className="header-logo mr-20"
                    />
                  </Drawer.Title>
                </Drawer.Header>

                <div className="mainSearch col-10 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                  <div className="button-grid items-center">
                    <div
                      ref={searchMenuRef}
                      className={`searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch  ${
                        isActiveDestination ? "-is-dd-wrap-active" : ""
                      }`}
                      onClick={handleDestinationClick}
                    >
                      <div
                        onBlur={handleBlur}
                        data-x-dd-click="searchMenu-loc"
                        style={{ cursor: "pointer" }}
                      >
                        <h4 className="text-15 fw-500 ls-2 lh-16">
                          Where would like to go?
                        </h4>
                        <div className="text-15 text-light-1 ls-2 lh-16 d-flex justify-content-between align-items-center">
                          <input
                            style={{ cursor: "pointer" }}
                            autoComplete="off"
                            type="search"
                            placeholder="Select Itinerary"
                            className="js-search js-dd-focus"
                            value={selectedLocation} // Set the input value to the selected location
                            onChange={(e) => {
                              setSelectedLocation(e.target.value);
                              setSearchTerm(e.target.value); // Update search term to filter results
                            }}
                          />{" "}
                          <FontAwesomeIcon
                            className="text-primary"
                            onClick={() =>
                              setIsActiveDestination(!isActiveDestination)
                            }
                            style={{ float: "right" }}
                            // className="mx-2 my-1"
                            icon={faAngleDown}
                          />
                        </div>
                      </div>

                      <div
                        className={`searchMenu-loc__field shadow-2 js-popup-window ${
                          isActiveDestination ? "-is-active" : ""
                        }`}
                        data-x-dd="searchMenu-loc"
                        data-x-dd-toggle="-is-active"
                      >
                        <div className="bg-white px-30 py-15 sm:px-0 sm:py-15 rounded-4">
                          <div className="y-gap-5 js-results">
                            {loading ? (
                              <Fragment>
                                <img
                                  src="/ship-wheel.svg"
                                  alt="Loading..."
                                  className="spinning-wheel d-block m-auto"
                                />
                                <p className="text-center m-auto">Loading...</p>
                              </Fragment>
                            ) : filteredLocations.length > 0 ? (
                              filteredLocations.map((location, index) => (
                                <div
                                  key={location.name}
                                  style={{
                                    margin: 0,
                                    border: 0,
                                    borderBottom:
                                      index + 1 != filteredLocations.length &&
                                      "1px solid #ddd",
                                    padding: 0,
                                  }}
                                >
                                  <button
                                    className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option"
                                    onClick={() =>
                                      handleLocationSelect(location)
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className=" icon-destination text-light-1 text-20 pt-4"></div>

                                      <div className="ml-10">
                                        <div className="text-15 lh-12 fw-500">
                                          {location.name}
                                        </div>
                                        <div className="text-14 lh-12 text-light-1 mt-5">
                                          {/* {location.description} */}
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              ))
                            ) : (
                              <div className="text-center py-15">
                                No results found"
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="bg-white  py-1 "
                          style={{
                            borderBottom: "4px solid #1976d2",
                            height: "auto",

                            overflow: "hidden",
                          }}
                        >
                          {" "}
                          <Button
                            className="mx-auto   pt-2 text-center d-block"
                            onClick={() => setIsActiveDestination(false)}
                          >
                            <FontAwesomeIcon
                              className="mx-2 my-1"
                              icon={faAngleDoubleUp}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                      <div>
                        <LightTooltip
                          title={
                            selectedDate
                              ? null
                              : "Please select a duration to view available dates"
                          }
                        >
                          <h4 className="text-15 fw-500 ls-2 lh-16">
                            Departs as early as
                          </h4>
                          <DatePicker
                            value={selectedDate}
                            onChange={handleDateChange}
                            placeholder={
                              selectedLocation
                                ? "Select Date"
                                : "Select duration first"
                            }
                            className="text-15 text-light-1 ls-2 lh-16"
                            disabledDate={disabledDate}
                            disabled={!selectedLocation}
                            renderValue={(value) => {
                              return format(value, "EEE, d MMM");
                            }}
                            oneTap
                          />{" "}
                        </LightTooltip>
                      </div>
                    </div>
                    <div
                      ref={GuestMenuRef}
                      className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                        isActiveGuest ? "-is-dd-wrap-active" : ""
                      }`}
                      onBlur={handleGuestBlur}
                    >
                      <div
                        data-x-dd-click="searchMenu-guests"
                        onClick={handleGuestClick}
                      >
                        <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
                        <div
                          className="text-14 text-light-1 ls-2 lh-16 d-flex align-items-center justify-content-between"
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            {" "}
                            <span className="js-count-adult">
                              {adults}
                            </span>{" "}
                            adults -{" "}
                            <span className="js-count-child">{children}</span>{" "}
                            children -{" "}
                            <span className="js-count-room">{rooms}</span>{" "}
                            cabin/suite
                            {rooms > 1 ? "s" : ""}
                          </div>
                          <FontAwesomeIcon
                            className="text-primary"
                            onClick={() =>
                              setIsActiveDestination(!isActiveDestination)
                            }
                            style={{ float: "right" }}
                            // className="mx-2 my-1"
                            icon={faAngleDown}
                          />
                        </div>{" "}
                      </div>

                      <div
                        className={`searchMenu-guests__field shadow-2  ${
                          isActiveGuest ? "-is-active" : ""
                        }`}
                      >
                        {" "}
                        <div className="bg-white px-30 py-30 rounded-4">
                          <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                              <div className="text-15 fw-500">Adults</div>
                              <div className="text-14 lh-12 text-light-1 mt-5">
                                Ages: +12
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex items-center js-counter">
                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                  onClick={() =>
                                    adults > 1 && handleDecrement(setAdults)
                                  }
                                >
                                  <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                  <div className="text-15">{adults}</div>
                                </div>

                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                  onClick={() => handleIncrement(setAdults)}
                                >
                                  <i className="icon-plus text-12"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="border-top-light mt-24 mb-24"></div>

                          <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                              <div className="text-15 lh-12 fw-500">
                                Children
                              </div>
                              <div className="text-14 lh-12 text-light-1 mt-5">
                                Ages: 2 to 11.99
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex items-center js-counter">
                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                  onClick={() => handleChildDecrement()}
                                  disabled={children <= 0}
                                >
                                  <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                  <div className="text-15">{children}</div>
                                </div>

                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                  onClick={() =>
                                    handleChildIncrement(setChildren)
                                  }
                                  disabled={children >= 2}
                                >
                                  <i className="icon-plus text-12"></i>
                                </button>
                              </div>
                            </div>

                            {/* Add age selectors for children */}
                            {children > 0 && (
                              <div className="col-12 mt-10">
                                {childrenAges.map((age, index) => (
                                  <div
                                    key={index}
                                    className="row y-gap-10 justify-between items-center mt-5"
                                  >
                                    <div className="col-auto">
                                      <div className="text-14 lh-12 text-light-1">
                                        Child {index + 1} age
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <select
                                        value={age}
                                        onChange={(e) =>
                                          handleAgeChange(
                                            index,
                                            parseInt(e.target.value)
                                          )
                                        }
                                        className="form-select text-primary text-14"
                                      >
                                        {[...Array(10)].map((_, i) => (
                                          <option key={i + 2} value={i + 2}>
                                            {i + 2} years
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="border-top-light mt-24 mb-24"></div>

                          <div className="row y-gap-10 justify-between items-center">
                            <div className="col-auto">
                              <div className="text-15 fw-500">
                                Cabins / Suites
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="d-flex items-center js-counter">
                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                  onClick={() =>
                                    rooms > 1 && handleDecrement(setRooms)
                                  }
                                >
                                  <i className="icon-minus text-12"></i>
                                </button>

                                <div className="flex-center size-20 ml-15 mr-15">
                                  <div className="text-15">{rooms}</div>
                                </div>

                                <button
                                  className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                  onClick={() => handleIncrement(setRooms)}
                                >
                                  <i className="icon-plus text-12"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="bg-white pb-1 "
                          style={{ borderBottom: "4px solid #1976d2" }}
                        >
                          {" "}
                          <Button
                            className="mx-auto  py-2 text-center d-block"
                            onClick={() => setIsActiveGuest(false)}
                          >
                            <FontAwesomeIcon
                              className="mx-2 my-1"
                              icon={faAngleDoubleUp}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="button-item">
                      <button
                        style={cursorStyle}
                        className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                        onClick={handleSearch}
                        disabled={isLoading}
                      >
                        <i className="icon-search text-20 mr-10"></i>
                        {isLoading ? "Loading..." : "Search"}
                      </button>
                    </div>{" "}
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={5000}
                      onClose={handleCloseSnackbar}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbarSeverity}
                        sx={{ width: "100%" }}
                      >
                        {snackbarMessage}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              </Drawer>
              <button
                className="fixed-bottom mx-auto text-18 py-5 text-center d-block"
                style={{
                  position: "fixed",
                  padding: " 40px 0",
                  bottom: "0",
                  left: "0",
                  height: "60px",
                  borderRadius: "30px 30px 0 0 ",
                  width: "100%",
                  backgroundColor: "#1976d2",
                  color: "white",
                }}
                onClick={() => setDrawerOpen(true)}
              >
                <i className="icon-search  mr-10"></i>
                Search
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
