import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const HeaderCruiseDetails = () => {
  const [isFixed, setIsFixed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        if (window.scrollY >= 100) {
          setIsFixed(true); // Make it fixed
        } else {
          setIsFixed(false); // Return to original position
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
    };
  }, []);
  return (
    <div>
      <div
        ref={ref} // Attach the ref to the div
        className={`singleMenu js-singleMenu  ${isFixed ? "-is-active" : ""}`} // Add a class based on isFixed state
      >
        <div className="singleMenu__content">
          <div className="container">
            <div className="row y-gap-20 justify-between items-center">
              <div className="col-auto">
                <div className="singleMenu__links row x-gap-30 y-gap-10">
                  <div className="col-auto">
                    <Link to="#overview">Overview</Link>
                  </div>
                  <div className="col-auto">
                    <Link to="#rooms">Rooms</Link>
                  </div>
                  <div className="col-auto">
                    <Link to="#itinerary">Itinerary</Link>
                  </div>

                  <div className="col-auto">
                    <Link to="#facilities">Facilities</Link>
                  </div>
                  <div className="col-auto">
                    <Link to="#decksPlan">DecksPlan</Link>
                  </div>
                  <div className="col-auto">
                    <Link to="#faq">Faq</Link>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row x-gap-15 y-gap-15 items-center">
                  {/* <div className="col-auto">
                    <div className="text-14">
                      From
                      <span className="text-22 text-dark-1 fw-500">$72</span>
                    </div>
                  </div> */}

                  <div className="col-auto">
                    <Link
                      to="#rooms"
                      className="button h-50 px-24 -dark-1 bg-blue-1 text-white"
                    >
                      Select Room{" "}
                      <div className="icon-arrow-top-right ml-15"></div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCruiseDetails;
