import { configureStore } from "@reduxjs/toolkit";
// import itinerariesPageSliceReducer from "./itinerariesPageSlice";
// import itineraryDetails from "./itineraryDetailsSlice";
// import facilitiesReducer from "./facilitiesSlice";
// import facilityDetails from "./facilityDetailsSlice";
// import sightseeingReducer from "./sightseeingSlice";
// import diningReducer from "./diningSlice";
// import diningDetails from "./diningDetailsSlice";
// import cruiseReducer from "./cruises-slice";
// import groupReducer from "./Group-slice";
// import roomsReducer from "./roomsSlice";
// import roomDetails from "./roomDetailsSlice";
// import faqReducer from "./faqSlice";
// import galleryReducer from "./gallerySlice";
// import contactReducer from "./contactSlice";
// import customerDataSlice from "./postData";
// import cabinBookingReducer from "./cabinBookingSlice";
// import contactSubmitReducer from "./contactSubmitSlice";
// import newsLetterReducer from "./newsLetterSlice";
// import bookingCruisesGroupReducer from "./groupBooking/bookGroupSlice";
// import nationalityReducer from "./groupBooking/nationalitySlice";
import layoutDataReducer from "./layoutSlice";
import durationsReducer from "./durationsSlice";
import homePageDataReducer from "./homePageSlice";
import durationsPerCruiseReducer from "./durationsPerCruiseSlice";
import cruiseTypeListingReducer from "./cruiseTypeListingSlice";
import cruisesListBookingReducer from "./groupBooking/cruiseListSlice";
import cruiseDetailsReducer from "./groupBooking/cruiseDetailsSlice";
import thanksData from "./groupBooking/getThanksPageSlice";
import checkOutReducer from "./groupBooking/checkOutSlice";
import languageReducer from "./languageSlice";
import extrasReducer from "./groupBooking/getExtraSlice";
import confirmationBookingReducer from "./groupBooking/confirmationBookingSlice";
export const store = configureStore({
  reducer: {
    language: languageReducer,
    // group: groupReducer,
    // itinerariesPage: itinerariesPageSliceReducer,
    // itineraryDetails: itineraryDetails,
    // sightseeing: sightseeingReducer,
    // dining: diningReducer,
    // diningDetails: diningDetails,
    // facilities: facilitiesReducer,
    // facilityDetails: facilityDetails,
    // rooms: roomsReducer,
    // roomDetails: roomDetails,
    // faq: faqReducer,
    // gallery: galleryReducer,
    // contact: contactReducer,
    // contactSubmit: contactSubmitReducer,
    // cabins: cabinBookingReducer,
    // customerData: customerDataSlice,
    // newsLetter: newsLetterReducer,
    // cruises: cruiseReducer,
    // group Booking
    // bookingCruisesGroup: bookingCruisesGroupReducer,
    // nationality: nationalityReducer,
    //*********************************************/
    layoutData: layoutDataReducer,
    durations: durationsReducer,
    homePageData: homePageDataReducer,
    cruiseTypeListing: cruiseTypeListingReducer,
    durationsPerCruise: durationsPerCruiseReducer,
    cruisesListBooking: cruisesListBookingReducer,
    cruiseDetails: cruiseDetailsReducer,
    extras: extrasReducer,
    checkOut: checkOutReducer,
    confirmationBooking: confirmationBookingReducer,
    thanksData: thanksData,
  },
});
