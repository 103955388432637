import React, {
  Fragment,
  Suspense,
  useCallback,
  useRef,
  useState,
} from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "rsuite/dist/rsuite.min.css";
import { Tooltip, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faClock,
  faLayerGroup,
  faMoneyBill1Wave,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
// import { BookingForm } from "../components/BookingForm";

import { Drawer } from "rsuite";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";

import HeaderCruiseDetails from "../../components/HeaderCruiseDetails";
import Fancybox from "../../components/fancybox-library/Fancybox";
import { fetchCruiseExtras } from "./../../data/groupBooking/getExtraSlice";
import { fetchCheckOut } from "../../data/groupBooking/checkOutSlice";
import { fetchCruiseDetails } from "../../data/groupBooking/cruiseDetailsSlice";
import BookingRightSideDetails from "../../components/BookingRightSideDetails";

const LazyShipImageFixed = React.lazy(() =>
  import("../../components/ShipFixedImage")
);
const CruisesDetails = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const operationID = queryParams.get("operationID");
  const durationID = queryParams.get("durationID");
  const opreationDate = queryParams.get("opreationDate");
  const numberRooms = queryParams.get("numberRooms");
  const adultNumber = queryParams.get("adultNumber");
  const childNumber = queryParams.get("childNumber");
  const childAgesParam = queryParams.get("childAges");
  const { cruiseDetails, loading, error } = useSelector(
    (state) => state.cruiseDetails
  );

  useEffect(() => {
    const childAges = childAgesParam
      ? childAgesParam.split(",").map(Number)
      : [];
    const payload = {
      durationID: durationID,
      opreationDate: opreationDate,
      numberRooms,
      listPassengersRoomsRequests: [
        {
          adultNumber,
          childNumber,
          childAges,
        },
      ],
    };
    if (!cruiseDetails?.result_Cruise) {
      dispatch(fetchCruiseDetails({ operationID, payload }))
        .unwrap()
        .catch((error) => {
          console.error("Failed to refetch cruise list:", error);
        });
    }
  }, [
    adultNumber,
    childAgesParam,
    childNumber,
    cruiseDetails?.result_Cruise,
    dispatch,
    durationID,
    numberRooms,
    operationID,
    opreationDate,
  ]);

  const handleReserveClick = useCallback(
    async (selectedCabin) => {
      if (cruiseDetails) {
        const payload = {
          durationID: cruiseDetails?.request?.durationID,
          opreationDate: cruiseDetails?.request?.opreationDate,
          numberRooms: cruiseDetails?.request?.numberRooms,
          listPassengersRoomsRequests: [
            {
              adultNumber:
                cruiseDetails?.request?.listPassengersRoomsRequests[0]
                  .adultNumber,
              childNumber:
                cruiseDetails?.request?.listPassengersRoomsRequests[0]
                  .childNumber,
              childAges:
                cruiseDetails?.request?.listPassengersRoomsRequests[0]
                  .childAges,
            },
          ],
          operatinID: cruiseDetails?.result_Cruise?.operationID,
          cruiseID: cruiseDetails?.result_Cruise?.curiseId,
          cbinID: selectedCabin.cabinID,
          cruiseRateId: selectedCabin.listRatesPerCabins[0].cruiseRateID,
        };

        const queryString = new URLSearchParams({
          operationID: payload.operatinID,
          durationID: payload.durationID,
          opreationDate: payload.opreationDate,
          numberRooms: payload.numberRooms,
          adultNumber: payload.listPassengersRoomsRequests[0].adultNumber,
          childNumber: payload.listPassengersRoomsRequests[0].childNumber,
          childAges: payload.listPassengersRoomsRequests[0].childAges.join(","),

          cruiseID: payload.cruiseID,
          cbinID: payload.cbinID,
          cruiseRateId: payload.cruiseRateId,
        }).toString();

        try {
          const resultAction = await dispatch(fetchCruiseExtras(payload));
          if (fetchCruiseExtras.fulfilled.match(resultAction)) {
            if (cruiseDetails.result_Cruise?.isHasExtra) {
              navigate(`/add-extra?${queryString}`);
            } else {
              const checkoutPayload = {
                ...payload,
                extrasIds: [],
              };
              dispatch(fetchCheckOut(checkoutPayload));
              navigate(`/checkout?${queryString}`);
            }
          } else {
            throw new Error(resultAction.error.message);
          }
        } catch (err) {
          alert(err.message || "An error occurred while searching");
        }
      }
    },
    [cruiseDetails, dispatch, navigate]
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    // Add null check before accessing scrollHeight
    if (contentRef.current) {
      if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
        setShowButton(true);
      }
    }
  }, [cruiseDetails?.result_Cruise]);

  const toggleText = () => {
    setIsExpanded((prev) => !prev);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Store which accordion item is currently open
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };
  const faqItems = [
    {
      question: "What do I need to hire a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How old do I have to be to rent a car?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Can I book a rental car for someone else?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How do I find the cheapest car hire deal?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "What should I look for when choosing a car hire?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  // Modify the drawer state to include selected cabin
  const [drawerState, setDrawerState] = useState({
    open: false,
    selectedCabin: null,
  });

  // Update the handler to accept cabin data
  const handleOpenDrawer = (cabin) => {
    setDrawerState({
      open: true,
      selectedCabin: cabin,
    });
  };

  const handleCloseDrawer = () => {
    setDrawerState({
      open: false,
      selectedCabin: null,
    });
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Fragment>
          <Helmet>
            <title>
              {cruiseDetails?.result_Cruise?.cruiseName ?? "Cruise Details"}
            </title>{" "}
            <style type="text/css">
              {`
                .header .header-menu .menu li a {
                  color: #051036;
                }
                .header .header-menu .menu .subnav > li > a {
                  color: #051036 !important;
                }
                .is-sticky.bg-dark-1 {
                  background-color: #fff !important;
                }
                // .is-sticky .menu li a{
                // color:#fff !important;
                // }
                .ratio-15\:9::before {
                  padding-bottom: 0 !important;
                }
                .list-disc li::marker {
                  color: #1976d2 !important;
                }
                    
              `}
            </style>
          </Helmet>

          <section className="row d-flex items-center  mt-90  ">
            <div className="  mt-40 py-40 bg-light-2 border-top-light">
              <div className="container d-flex justify-content-between m-auto px-1    y-gap-30 items-center ">
                <div className="col-auto pe-1">
                  <Tooltip arrow title="Return To Choose Cruise">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-1)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cruise
                      </div>
                    </div>
                  </Tooltip>
                </div>{" "}
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <div className="col-auto  px-1">
                  <div className="d-flex items-center">
                    <div className="size-40 rounded-full flex-center bg-blue-1">
                      <span className=" text-16 text-white">2</span>
                    </div>
                    <div className="text-18 fw-500 ml-10"> Choose Cabin </div>
                  </div>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 bg-border"></div>
                </div>
                {cruiseDetails?.result_Cruise?.isHasExtra && (
                  <Fragment>
                    <div className="col-auto px-1">
                      <div className="d-flex items-center">
                        <div className="size-40 rounded-full flex-center bg-blue-2">
                          <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                            3
                          </div>
                        </div>
                        <div className="text-18 fw-500 ml-10">
                          Extras selection
                        </div>
                      </div>
                    </div>

                    <div className="col px-1">
                      <div className="w-full h-1 bg-border"></div>
                    </div>
                  </Fragment>
                )}
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                      4
                    </div>
                    <div className="text-18 fw-500 ml-10">Checkout </div>
                  </div>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 bg-border"></div>
                </div>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                      5
                    </div>
                    <div className="text-18 fw-500 ml-10">
                      Booking Completed!{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <HeaderCruiseDetails />
          <section className="pt-40">
            <div className="container">
              <div className="row y-gap-15 justify-between items-end">
                <div className="col-auto">
                  <h1 className="text-30 fw-600">
                    {cruiseDetails?.result_Cruise?.cruiseName}
                  </h1>

                  <div className="row x-gap-20 y-gap-20 items-center pt-10">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div
                          className="d-flex x-gap-5 items-center"
                          dangerouslySetInnerHTML={{
                            __html:
                              cruiseDetails?.result_Cruise?.curiseStarCode,
                          }}
                        />
                        <div className="text-14 text-light-1 ml-10">
{cruiseDetails?.result_Cruise?.cruiseBrandNameSys  ? (cruiseDetails?.result_Cruise?.cruiseBrandNameSys) : null
                         }{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="row x-gap-10 items-center">
                        <div className="col-auto">
                          <div className="d-flex x-gap-5 items-center">
                            <i className="icon-placeholder text-16 text-light-1"></i>
                            <div className="text-15 text-light-1">
                              {
                                cruiseDetails?.result_Cruise
                                  ?.destinationNameFrom
                              }{" "}
                              -{" "}
                              {cruiseDetails?.result_Cruise?.destinationNameTo}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="row x-gap-10 items-center">
                        <div className="col-auto">
                          <div className="d-flex x-gap-5 items-center">
                            <FontAwesomeIcon icon={faUsersViewfinder} />
                            <div className="text-15 text-light-1">
                              {
                                cruiseDetails?.request
                                  ?.listPassengersRoomsRequests[0].adultNumber
                              }{" "}
                              Adults
                              {cruiseDetails?.request
                                ?.listPassengersRoomsRequests[0]
                                .childNumber && (
                                <>
                                  {" "}
                                  &{" "}
                                  {
                                    cruiseDetails?.request
                                      ?.listPassengersRoomsRequests[0]
                                      .childNumber
                                  }{" "}
                                  Child
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-40 js-pin-container">
            <div className="container">
              <div className="row y-gap-30">
                <div className="col-lg-8">
                  <div className="relative d-flex justify-center overflow-hidden gallery">
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={1} // Equivalent to `data-slider-cols="base-1"`
                      navigation={{
                        prevEl: ".js-img-prev",
                        nextEl: ".js-img-next",
                      }}
                      className="swiper-wrapper"
                    >
                      {cruiseDetails.result_Cruise?.cruiseGallery?.length >
                      0 ? (
                        cruiseDetails.result_Cruise?.cruiseGallery.map(
                          (image, index) => (
                            <SwiperSlide key={index}>
                              <img
                                src={image}
                                alt={cruiseDetails.result_Cruise?.cruiseName}
                                className="rounded-4 col-12 h-full object-cover"
                              />
                            </SwiperSlide>
                          )
                        )
                      ) : (
                        <SwiperSlide>
                          <div className="no-image-found d-flex justify-center  ">
                            <p className="text-danger">No image found</p>
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>

                    {/* Navigation Buttons */}
                    <div className="absolute h-100 col-11">
                      <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-prev">
                        <i className="icon icon-chevron-left text-12"></i>
                      </button>
                      <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-next">
                        <i className="icon icon-chevron-right text-12"></i>
                      </button>
                    </div>

                    {/* Gallery Link */}
                    <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                      <Fancybox>
                        <a
                          href={cruiseDetails.result_Cruise?.cruiseGallery[0]}
                          className="button -blue-1 px-24 py-15 bg-white text-dark-1"
                          data-fancybox="main-gallery"
                          aria-label="Gallery image 1" // Add this line
                        >
                          See All Photos
                        </a>
                        {/* to strt from image 2  */}
                        {cruiseDetails.result_Cruise?.cruiseGallery?.length > 0
                          ? cruiseDetails.result_Cruise?.cruiseGallery
                              .slice(1)
                              .map((image, index) => (
                                <a
                                  key={index}
                                  href={image}
                                  className="d-none"
                                  data-fancybox="main-gallery"
                                  aria-label={`Gallery image ${index + 2}`} // Add this line
                                ></a>
                              ))
                          : null}
                      </Fancybox>
                    </div>
                  </div>

                  <span id="overview"></span>
                  <h3 className="text-22 fw-500 mt-30">Highlights</h3>

                  <div className="row y-gap-30 justify-between pt-20">
                    <div className="col-md-auto col-6">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faClock}
                          className="text-22 text-blue-1 mr-10"
                        />
                        <div className="text-15 lh-15">
                          Duration: <br />{" "}
                          {cruiseDetails?.result_Cruise?.itineraryName}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-auto col-6">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faLayerGroup}
                          className="text-22 text-blue-1 mr-10"
                        />
                        <div className="text-15 lh-15">
                          Decks: <br />{" "}
                          {cruiseDetails?.result_Cruise?.numberOfDecks}{" "}
                          Luxurious Decks
                        </div>
                      </div>
                    </div>

                    <div className="col-md-auto col-6">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faMoneyBill1Wave}
                          className="text-22 text-blue-1 mr-10"
                        />
                        <div className="text-15 lh-15">
                          Price: <br /> From _$
                        </div>
                      </div>
                    </div>

                    <div className="col-md-auto col-6">
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faBan}
                          className="text-22 text-blue-1 mr-10"
                        />
                        <div className="text-15 lh-15 ">
                          Free cancellation <br />
                          <Link
                            to="#cancellation-policy"
                            className="text-blue-1 underline"
                          >
                            Learn more{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top-light mt-40 mb-40"></div>

                  <div className="row x-gap-40 y-gap-40">
                    <div className="col-12">
                      <h3 className="text-22 fw-500">Overview </h3>

                      {/* Text content */}
                      <div
                        className={`text-dark-1 text-15 mt-20 ${
                          isExpanded ? "expanded" : "collapsed"
                        }`}
                        ref={contentRef}
                        style={{
                          maxHeight: isExpanded ? "none" : "3em", // 2 lines height (adjust based on line height)
                          overflow: "hidden",
                          lineHeight: "1.5em", // Adjust based on your design's line height
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              cruiseDetails?.result_Cruise?.cruiseDescription,
                          }}
                        />
                        {cruiseDetails?.result_Cruise?.cruiseAccommodation ? (
                          <>
                            {" "}
                            <h6 className=" my-3 d-flex align-items-center  text-dark fw-400 ">
                              <i className="icon-bed text-blue-1 mr-10"></i>{" "}
                              <span>Accommodation</span>
                            </h6>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  cruiseDetails?.result_Cruise
                                    ?.cruiseAccommodation,
                              }}
                            />
                          </>
                        ) : null}
                        {cruiseDetails?.result_Cruise?.cruiseDining ? (
                          <>
                            {" "}
                            <h6 className=" my-3 d-flex align-items-center  text-dark fw-400 ">
                              <i className="icon-food text-blue-1 mr-10"></i>{" "}
                              <span>Dining</span>
                            </h6>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  cruiseDetails?.result_Cruise?.cruiseDining,
                              }}
                            />
                          </>
                        ) : null}
                        {cruiseDetails?.result_Cruise?.cruiseFacilities ? (
                          <>
                            {" "}
                            <h6 className=" my-3 d-flex align-items-center  text-dark fw-400 ">
                              <i className="icon-ski text-blue-1 mr-10"></i>{" "}
                              <span>Facilities</span>
                            </h6>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  cruiseDetails?.result_Cruise
                                    ?.cruiseFacilities,
                              }}
                            />
                          </>
                        ) : null}
                      </div>

                      {/* Show More / Show Less button */}
                      {showButton && (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleText();
                          }}
                          className="d-block text-14 text-blue-1 fw-500 underline mt-10"
                        >
                          {isExpanded ? "Show Less" : "Show More"}
                        </button>
                      )}
                    </div>
                    {cruiseDetails?.result_Cruise?.cruiseSpokenLanguage.length >
                    0 ? (
                      <div
                        className="col-md-12 border-top-light"
                        id="cancellation-policy"
                      >
                        <h5 className="text-16 fw-500">Available languages </h5>
                        <div className="text-15 mt-10">
                          {cruiseDetails?.result_Cruise?.cruiseSpokenLanguage.map(
                            (language, index) => (
                              <span key={index}>
                                {language}{" "}
                                {index + 1 <
                                  cruiseDetails?.result_Cruise
                                    ?.cruiseSpokenLanguage.length && ","}{" "}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}
                    {cruiseDetails?.result_Cruise?.cancelationPolicies?.length >
                    0 ? (
                      <div className="col-md-12 border-top-light">
                        <h5 className="text-16 fw-500">Cancellation policy </h5>
                        <div className="text-15 mt-10">
                          <ul className="list-disc text-15 mt-10">
                            {cruiseDetails?.result_Cruise?.cancelationPolicies?.map(
                              (item, index) => (
                                <li key={index} className="policy">
                                  {item}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    {cruiseDetails?.result_Cruise?.itineraryNote ? (
                      <div className="col-12 border-top-light">
                        <h5 className="text-16 fw-500">Notes </h5>
                        <div
                          className="text-dark-1 text-15"
                          dangerouslySetInnerHTML={{
                            __html: cruiseDetails?.result_Cruise?.itineraryNote,
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {cruiseDetails?.result_Cruise?.itineraryIncluded ? (
                    <div className="mt-40 border-top-light">
                      <div className="row x-gap-40 y-gap-40 pt-40">
                        <div className="col-12">
                          <h3 className="text-22 fw-500">What's Included </h3>

                          <div className="row x-gap-40 y-gap-40 pt-20">
                            <div className="col-md-6">
                              <div className="text-dark-1 text-15">
                                {/* <i className="icon-check text-green-2 text-10 mr-10"></i>{" "} */}
                                <div
                                  id="included"
                                  className="text-dark-1 text-15"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      cruiseDetails?.result_Cruise
                                        ?.itineraryIncluded,
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div
                                className="text-dark-1 text-15"
                                id="exclude"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    cruiseDetails?.result_Cruise
                                      ?.itineraryExcluded,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-lg-4">
                  <BookingRightSideDetails cruiseDetails={cruiseDetails} />
                </div>
              </div>
            </div>
          </section>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyShipImageFixed />
          </Suspense>

          <section id="rooms" className="pt-30   ">
            <div className="container">
              <div className="row pb-20">
                <div className="col-auto">
                  <h3 className="text-22 fw-500">Staterooms</h3>
                </div>
              </div>

              {cruiseDetails?.result_Cruise?.cabinsLists?.map(
                (cabin, index) => (
                  <div className="mt-20" key={index}>
                    <div className="bg-blue-2 rounded-4 px-30 py-30 sm:px-20 sm:py-20">
                      <div className="row y-gap-30 align-items-center">
                        <div className="col-xl-auto">
                          <div className="ratio ratio-1:1 col-12 col-md-4 col-xl-12">
                            <img
                              src={cabin.cabinPhoto}
                              alt=""
                              className="img-ratio rounded-4"
                            />
                          </div>

                          <div className="">
                            <div className="text-18 fw-500 mt-10">
                              {cabin.cabinName}
                            </div>

                            <div className="y-gap-5 pt-5">
                              {cabin.mostPopularItems?.map((item, index) => (
                                <div
                                  className="d-flex items-center"
                                  key={index}
                                >
                                  {/* <i className="icon-no-smoke text-20 mr-10"></i> */}
                                  <img
                                    width="20"
                                    height="20"
                                    className="mr-10"
                                    src={item.cabinFeaturesItemName}
                                    alt={item.cabinFeaturesItemName}
                                  />
                                  <div className="text-15">
                                    {item.cabinFeaturesItemName}
                                  </div>
                                </div>
                              ))}
                            </div>

                            <button
                              type="button"
                              onClick={() => handleOpenDrawer(cabin)}
                              className="d-block text-15 fw-500 underline text-blue-1 mt-15"
                            >
                              Show {cabin.cabinTypeNameSys} Information{" "}
                            </button>
                          </div>
                        </div>
                        <Drawer
                          size="full"
                          placement="right"
                          open={drawerState.open}
                          onClose={handleCloseDrawer}
                        >
                          <Drawer.Header>
                            <Drawer.Title>
                              {drawerState?.selectedCabin?.cabinTypeNameSys}{" "}
                              Details
                            </Drawer.Title>
                          </Drawer.Header>
                          <Drawer.Body>
                            {/* <Placeholder.Paragraph rows={8} /> */}
                            <section className="pt-40">
                              <div className="container">
                                <div className="row y-gap-15 justify-between items-end">
                                  <div className="col-lg-10 mx-auto">
                                    <h1 className="text-30 fw-600">
                                      {drawerState?.selectedCabin?.cabinName}
                                    </h1>

                                    <div className="row x-gap-20 y-gap-20 items-center pt-10">
                                      <div className="col-auto">
                                        <div className="d-flex items-center">
                                          <div className="text-14 text-light-1 ml-10">
                                            {
                                              drawerState?.selectedCabin
                                                ?.cabinSize
                                            }{" "}
                                            {
                                              drawerState?.selectedCabin
                                                ?.sizeMeasurement
                                            }{" "}
                                            |{" "}
                                            {
                                              drawerState?.selectedCabin
                                                ?.cabinOccupancy
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section className="pt-40 js-pin-container">
                              <div className="container">
                                <div className="row y-gap-30">
                                  <div className="col-lg-10 mx-auto">
                                    <div className="relative d-flex justify-center overflow-hidden gallery">
                                      <Swiper
                                        modules={[Navigation]}
                                        slidesPerView={1} // Equivalent to `data-slider-cols="base-1"`
                                        navigation={{
                                          prevEl: ".js-img-prev",
                                          nextEl: ".js-img-next",
                                        }}
                                        className="swiper-wrapper"
                                      >
                                        {drawerState?.selectedCabin?.cabinPhotos
                                          ?.length > 0 ? (
                                          drawerState?.selectedCabin?.cabinPhotos.map(
                                            (image, index) => (
                                              <SwiperSlide key={index}>
                                                <img
                                                  src={image}
                                                  alt={
                                                    drawerState?.selectedCabin
                                                      ?.cruiseName
                                                  }
                                                  className="rounded-4 col-12 h-full object-cover"
                                                />
                                              </SwiperSlide>
                                            )
                                          )
                                        ) : (
                                          <SwiperSlide>
                                            <div className="no-image-found d-flex justify-center  ">
                                              <p className="text-danger">
                                                No image found
                                              </p>
                                            </div>
                                          </SwiperSlide>
                                        )}
                                      </Swiper>

                                      {/* Navigation Buttons */}
                                      <div className="absolute h-100 col-11">
                                        <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-prev">
                                          <i className="icon icon-chevron-left text-12"></i>
                                        </button>
                                        <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-img-next">
                                          <i className="icon icon-chevron-right text-12"></i>
                                        </button>
                                      </div>

                                      {/* Gallery Link */}
                                      <div className="absolute h-full col-12 z-2 px-20 py-20 d-flex justify-end items-end">
                                        {drawerState?.selectedCabin?.cabinPhotos
                                          ?.length > 0 ? (
                                          <Fancybox>
                                            <a
                                              href={
                                                drawerState?.selectedCabin
                                                  ?.cabinPhotos[0]
                                              }
                                              className="button -blue-1 px-24 py-15 bg-white text-dark-1"
                                              data-fancybox="cabin-gallery"
                                              aria-label="Gallery image 1" // Add this line
                                            >
                                              See All Photos
                                            </a>

                                            {drawerState?.selectedCabin?.cabinPhotos
                                              .slice(1)
                                              .map((image, index) => (
                                                <a
                                                  key={index}
                                                  href={image}
                                                  className="d-none"
                                                  data-fancybox="cabin-gallery"
                                                  aria-label={`Gallery image ${
                                                    index + 2
                                                  }`} // Add this line
                                                ></a>
                                              ))}
                                          </Fancybox>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="border-top-light mt-40 mb-40"></div>

                                    <div className="row x-gap-40 y-gap-40">
                                      <div className="col-12">
                                        <h3 className="text-22 fw-500">
                                          Overview{" "}
                                        </h3>

                                        {/* Text content */}
                                        {drawerState?.selectedCabin
                                          ?.cabinDescription ? (
                                          <Fragment>
                                            <div
                                              className={`text-dark-1 text-15 mt-20 ${
                                                isExpanded
                                                  ? "expanded"
                                                  : "collapsed"
                                              }`}
                                              ref={contentRef}
                                              style={{
                                                maxHeight: isExpanded
                                                  ? "none"
                                                  : "3em",
                                                overflow: "hidden",
                                                lineHeight: "1.5em",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  drawerState?.selectedCabin
                                                    ?.cabinDescription,
                                              }}
                                            />

                                            {showButton && (
                                              <button
                                                type="button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  toggleText();
                                                }}
                                                className="d-block text-14 text-blue-1 fw-500 underline mt-10"
                                              >
                                                {isExpanded
                                                  ? "Show Less"
                                                  : "Show More"}
                                              </button>
                                            )}
                                          </Fragment>
                                        ) : (
                                          <p className="text-danger">
                                            No description found
                                          </p>
                                        )}
                                      </div>
                                      {drawerState?.selectedCabin
                                        ?.listCabinFeatures?.length > 0 ? (
                                        <div className="row x-gap-40 y-gap-40">
                                          <div className="col-12">
                                            <h3 className="text-22 fw-500">
                                              Facilities{" "}
                                            </h3>

                                            <div className="row x-gap-40 y-gap-40 pt-20">
                                              {drawerState?.selectedCabin?.listCabinFeatures?.map(
                                                (item, index) => (
                                                  <div
                                                    className="col-xl-4"
                                                    key={
                                                      item.cabinFeaturesTypeID
                                                    }
                                                  >
                                                    <div className="row y-gap-30">
                                                      <div className="col-12">
                                                        <div className="">
                                                          <div className="d-flex items-center text-16 fw-500">
                                                            <i className="icon-check text-10 mr-20"></i>
                                                            {
                                                              item.cabinFeaturesTypeName
                                                            }
                                                          </div>

                                                          <ul className="text-15 pt-10">
                                                            {item.cabinsListFeaturesTypesItems?.map(
                                                              (subItem) => (
                                                                <li
                                                                  className="d-flex items-center"
                                                                  key={
                                                                    subItem.cabinFeaturesItemID
                                                                  }
                                                                >
                                                                  <img
                                                                    className=" mr-20"
                                                                    src={
                                                                      subItem.cabinFeaturesItemIcon
                                                                    }
                                                                    alt={
                                                                      subItem.cabinFeaturesItemName
                                                                    }
                                                                  />
                                                                  {
                                                                    subItem.cabinFeaturesItemName
                                                                  }
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </Drawer.Body>
                        </Drawer>
                        <div className="col-xl">
                          {cabin.listRatesPerCabins.map((rate, index) => (
                            <div
                              className="bg-white rounded-4 px-30 py-30 my-3"
                              key={index}
                            >
                              <div className="row y-gap-30">
                                <div className="col-lg col-md-6">
                                  <div className="text-15 fw-500 mb-10">
                                    {rate.rateName}
                                  </div>
                                  <p className="text-15   mb-10">
                                    {rate.rateDescription}
                                  </p>
                                  <div className="y-gap-5">
                                    <div className="d-flex items-center text-green-2">
                                      <i className="icon-check text-12 mr-10"></i>
                                      <div className="text-15">
                                        Pay at the hotel{" "}
                                      </div>
                                    </div>

                                    <div className="d-flex items-center text-green-2">
                                      <i className="icon-check text-12 mr-10"></i>
                                      <div className="text-15">
                                        Pay nothing until March 30, 2022{" "}
                                      </div>
                                    </div>

                                    <div className="d-flex items-center text-green-2">
                                      <i className="icon-check text-12 mr-10"></i>
                                      <div className="text-15">
                                        Free cancellation before April 1, 2022{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-auto col-md-6 border-left-light lg:border-none">
                                  <div className="px-40 lg:px-0">
                                    <div className="text-15 fw-500 mb-20">
                                      guests{" "}
                                    </div>

                                    <div className="d-flex justify-content-lg-center g-5 align-items-end text-light-1 mb-1">
                                      {Array.from(
                                        {
                                          length:
                                            cruiseDetails?.request
                                              ?.listPassengersRoomsRequests[0]
                                              .adultNumber,
                                        },
                                        (_, index) => (
                                          <div
                                            key={index}
                                            className="icon-man text-30 "
                                          ></div>
                                        )
                                      )}
                                      {Array.from(
                                        {
                                          length:
                                            cruiseDetails?.request
                                              ?.listPassengersRoomsRequests[0]
                                              .childNumber,
                                        },
                                        (_, index) => (
                                          <div
                                            key={index}
                                            className="icon-man text-20"
                                          ></div>
                                        )
                                      )}
                                    </div>
                                    <span className=" text-10 text-secondary">
                                      (
                                      {
                                        cruiseDetails?.request
                                          ?.listPassengersRoomsRequests[0]
                                          .adultNumber
                                      }{" "}
                                      Adults &{" "}
                                      {
                                        cruiseDetails?.request
                                          ?.listPassengersRoomsRequests[0]
                                          .childNumber
                                      }{" "}
                                      Child){" "}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-auto col-md-6 border-left-light lg:border-none">
                                  <div className="mx-auto text-center d-flex flex-column justify-content-center align-items-center px-40 lg:px-0">
                                    <div className="text-15 fw-500 mb-20">
                                      {cabin.cabinTypeNameSys}s
                                    </div>
                                    <div>
                                      {" "}
                                      {cruiseDetails?.request?.numberRooms > 1
                                        ? cruiseDetails?.request?.numberRooms +
                                          " " +
                                          cabin.cabinTypeNameSys +
                                          "s"
                                        : cruiseDetails?.request?.numberRooms +
                                          " " +
                                          cabin.cabinTypeNameSys}
                                    </div>
                                  </div>{" "}
                                  <span className="d-block text-center mt-1 text-10 text-secondary">
                                    ( {cabin.cabinName}){" "}
                                  </span>
                                </div>

                                <div className="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                                  <div className="pl-40 lg:pl-0">
                                    <div className="text-20 lh-14 fw-500">
                                      {rate.cabinRatePriceCurrency}
                                      {rate.cabinRatePriceTotal}{" "}
                                      <span className="text-14 lh-14 text-light-1 mb-5">
                                        /{" "}
                                        {
                                          cruiseDetails?.result_Cruise
                                            ?.itineraryNightNumbers
                                        }{" "}
                                        Nights
                                      </span>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() => handleReserveClick(cabin)}
                                      className="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                                    >
                                      Reserve{" "}
                                      <div className="icon-arrow-top-right ml-15"></div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
          <div id="itinerary"></div>
          <div className="container mt-40 mb-40">
            <div className="border-top-light"></div>
          </div>
          <section>
            <div className="container">
              <h3 className="text-22 fw-500 mb-20">Itinerary </h3>

              <div className="row align-items-center y-gap-30">
                <div className="col-lg-5">
                  <div className="relative">
                    <div className="border-test"></div>

                    <div className="accordion -map row y-gap-20">
                      {cruiseDetails?.result_Cruise?.listItineraryDays.map(
                        (item, index) => (
                          <div className="col-12" key={index}>
                            <div
                              className={`accordion__item is-active${
                                activeIndex === index ? "is-active" : ""
                              }`}
                            >
                              <div className="d-flex">
                                <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                                  <div className="text-14 fw-500">
                                    {index + 1}
                                  </div>
                                </div>

                                <div className="ml-20">
                                  <div className="text-16 lh-15 fw-500">
                                    {item.dayTitleName}
                                  </div>

                                  {activeIndex === index && (
                                    <div
                                      className="accordion__content"
                                      style={{
                                        maxHeight: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="pt-15 pb-15">
                                        <img
                                          src={item.dayPhoto}
                                          alt={item.dayTitleName}
                                          className="rounded-4 mt-15"
                                        />
                                        <div
                                          className="text-14 lh-17 mt-15"
                                          dangerouslySetInnerHTML={{
                                            __html: item.dayDetails,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="accordion__button">
                                    <button
                                      type="button"
                                      className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                                      onClick={() =>
                                        handleAccordionToggle(index)
                                      }
                                    >
                                      {activeIndex === index
                                        ? "See less"
                                        : "See More & View photo"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <img
                    loading="lazy"
                    style={{
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px #ccc ",
                    }}
                    src={cruiseDetails?.result_Cruise?.itineraryMap}
                    alt="itinerary map"
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="container mt-40 mb-40">
            <div className="border-top-light"></div>
          </div>
          <div id="facilities"></div>
          <section className="mt-40">
            <div className="container">
              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Facilities </h3>

                  <div className="row x-gap-40 y-gap-40 pt-20">
                    <div className="col-xl-4">
                      <div className="row y-gap-30">
                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-bathtub text-20 mr-10"></i>
                              Accommodation
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Towels
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Bath or shower
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Private bathroom
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Toilet
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Free toiletries
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Hairdryer
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Bath
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-bed text-20 mr-10"></i>
                              Sleeping
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Linen
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Wardrobe or closet
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-bell-ring text-20 mr-10"></i>
                              services
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Invoice provided
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Private check-in/check-out
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Luggage storage
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                24-hour front desk
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="row y-gap-30">
                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-tv text-20 mr-10"></i>
                              TV &amp; Technology
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Flat-screen TV
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Satellite channels
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Radio
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Telephone
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                TV
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-juice text-20 mr-10"></i>
                              Food &amp; Drink
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Kid meals
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Special diet menus ( request)
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Breakfast
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Bar
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Restaurant
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Tea/Coffee maker
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-washing-machine text-20 mr-10"></i>
                              services
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Daily housekeeping
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Dry cleaning
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Laundry
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="row y-gap-30">
                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-shield text-20 mr-10"></i>
                              Privacy &amp; security
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Fire extinguishers
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                CCTV in common
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Smoke alarms
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                24-hour security
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="">
                            <div className="d-flex items-center text-16 fw-500">
                              <i className="icon-city-2 text-20 mr-10"></i>
                              Others
                            </div>

                            <ul className="text-15 pt-10">
                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Hypoallergenic
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Non-smoking throughout
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Wake-up service
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Heating
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Packed lunches
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Carpeted
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Lift
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Fan
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Family rooms
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Facilities for disabled ______
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Ironing facilities
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Non-smoking rooms
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Iron
                              </li>

                              <li className="d-flex items-center">
                                <i className="icon-check text-10 mr-20"></i>
                                Room service
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container mt-40 mb-40">
            <div className="border-top-light"></div>
          </div>
          <div id="decksPlan"></div>
          <section className="mt-40">
            <div className="container">
              <div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-22 fw-500">DecksPlan </h3>
                  </div>
                </div>

                <div className="row x-gap-50 y-gap-30 pt-20">
                  <img
                    src={cruiseDetails?.result_Cruise?.cruiseDeckPlan}
                    alt={
                      cruiseDetails?.result_Cruise?.numberOfDecks + "DecksPlan"
                    }
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="faq" className="pt-40 layout-pb-md">
            <div className="container">
              <div className="pt-40 border-top-light">
                <div className="row y-gap-20">
                  <div className="col-lg-4">
                    <h2 className="text-22 fw-500">
                      FAQs about <br />{" "}
                      {cruiseDetails?.result_Cruise?.cruiseName}
                    </h2>
                  </div>

                  <div className="col-lg-8">
                    <div className="accordion -simple row y-gap-20">
                      {faqItems.map((item, index) => (
                        <div className="col-12" key={index}>
                          <div className="accordion__item px-20 py-20 border-light rounded-4 ">
                            <div
                              className="accordion__button d-flex items-center "
                              onClick={() => toggleAccordion(index)}
                            >
                              <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
                                <i
                                  className={
                                    activeFaq === index
                                      ? "icon-minus"
                                      : "icon-plus"
                                  }
                                ></i>
                              </div>
                              <div className="button text-dark-1">
                                {item.question}
                              </div>
                            </div>
                            {activeFaq === index && (
                              <div
                                className="accordion__content"
                                style={{ maxHeight: "100%" }}
                              >
                                <div className="pt-20 pl-60">
                                  <p className="text-15">{item.answer}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="layout-pt-md layout-pb-lg">
            <div className="container">
              <div className="row justify-center text-center">
                <div className="col-auto">
                  <div className="sectionTitle -md">
                    <h2 className="sectionTitle__title">
                      Popular properties similar to Suntimes Nile Cruise{" "}
                    </h2>
                    <p className=" sectionTitle__text mt-5 sm:mt-0">
                      Interdum et malesuada fames ante ipsum{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row y-gap-30 pt-40 sm:pt-20">
                <div className="col-xl-3 col-lg-3 col-sm-6">
                  <Link to="#." className="hotelsCard -type-1 ">
                    <div className="hotelsCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img
                            className="rounded-4 col-12"
                            src="/titsolutions/img/hotels/1.png"
                            alt="image"
                          />
                        </div>

                        <div className="cardImage__wishlist">
                          <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                            <i className="icon-heart text-12"></i>
                          </button>
                        </div>

                        <div className="cardImage__leftBadge">
                          <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-dark-1 text-white">
                            Breakfast included
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hotelsCard__content mt-10">
                      <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>The Montcalm At Brewery City </span>
                      </h4>

                      <p className="text-light-1 lh-14 text-14 mt-5">
                        Westminster Borough, London{" "}
                      </p>

                      <div className="d-flex items-center mt-20">
                        <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                          4.8{" "}
                        </div>
                        <div className="text-14 text-dark-1 fw-500 ml-10">
                          Exceptional{" "}
                        </div>
                        <div className="text-14 text-light-1 ml-10">
                          3,014 reviews{" "}
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="fw-500">
                          Starting from{" "}
                          <span className="text-blue-1">US$72 </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6">
                  <Link to="#." className="hotelsCard -type-1 ">
                    <div className="hotelsCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img
                            className="rounded-4 col-12"
                            src="/titsolutions/img/hotels/4.png"
                            alt="image"
                          />
                        </div>

                        <div className="cardImage__wishlist">
                          <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                            <i className="icon-heart text-12"></i>
                          </button>
                        </div>

                        <div className="cardImage__leftBadge">
                          <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                            Top Rated
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hotelsCard__content mt-10">
                      <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>
                          DoubleTree by Hilton Hotel York Times Square West{" "}
                        </span>
                      </h4>

                      <p className="text-light-1 lh-14 text-14 mt-5">
                        Vaticano Prati, Rome{" "}
                      </p>

                      <div className="d-flex items-center mt-20">
                        <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                          4.8{" "}
                        </div>
                        <div className="text-14 text-dark-1 fw-500 ml-10">
                          Exceptional{" "}
                        </div>
                        <div className="text-14 text-light-1 ml-10">
                          3,014 reviews{" "}
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="fw-500">
                          Starting from{" "}
                          <span className="text-blue-1">US$72 </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-3 col-sm-6">
                  <Link to="#." className="hotelsCard -type-1 ">
                    <div className="hotelsCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img
                            className="rounded-4 col-12"
                            src="/titsolutions/img/hotels/3.png"
                            alt="image"
                          />
                        </div>

                        <div className="cardImage__wishlist">
                          <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                            <i className="icon-heart text-12"></i>
                          </button>
                        </div>

                        <div className="cardImage__leftBadge">
                          <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-blue-1 text-white">
                            Best Seller
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hotelsCard__content mt-10">
                      <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>The Westin New York Times Square </span>
                      </h4>

                      <p className="text-light-1 lh-14 text-14 mt-5">
                        Manhattan, New York{" "}
                      </p>

                      <div className="d-flex items-center mt-20">
                        <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                          4.8{" "}
                        </div>
                        <div className="text-14 text-dark-1 fw-500 ml-10">
                          Exceptional{" "}
                        </div>
                        <div className="text-14 text-light-1 ml-10">
                          3,014 reviews{" "}
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="fw-500">
                          Starting from{" "}
                          <span className="text-blue-1">US$72 </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-3 col-sm-6">
                  <Link to="#." className="hotelsCard -type-1 ">
                    <div className="hotelsCard__image">
                      <div className="cardImage ratio ratio-1:1">
                        <div className="cardImage__content">
                          <img
                            className="rounded-4 col-12"
                            src="/titsolutions/img/hotels/4.png"
                            alt="image"
                          />
                        </div>

                        <div className="cardImage__wishlist">
                          <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                            <i className="icon-heart text-12"></i>
                          </button>
                        </div>

                        <div className="cardImage__leftBadge">
                          <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                            Top Rated
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hotelsCard__content mt-10">
                      <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                        <span>
                          DoubleTree by Hilton Hotel York Times Square West{" "}
                        </span>
                      </h4>

                      <p className="text-light-1 lh-14 text-14 mt-5">
                        Vaticano Prati, Rome{" "}
                      </p>

                      <div className="d-flex items-center mt-20">
                        <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                          4.8{" "}
                        </div>
                        <div className="text-14 text-dark-1 fw-500 ml-10">
                          Exceptional{" "}
                        </div>
                        <div className="text-14 text-light-1 ml-10">
                          3,014 reviews{" "}
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="fw-500">
                          Starting from{" "}
                          <span className="text-blue-1">US$72 </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CruisesDetails;
