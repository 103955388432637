import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FilterLeftSide from "./FilterLeftSide";
import LoaderInternal from "../../LoaderInternal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { fetchCruiseTypeListing } from "../../data/cruiseTypeListingSlice";
import { Helmet } from "react-helmet";
import { BookingFormListing } from "../../components/BookingFormListing";
import { BookingForm } from "../../components/BookingForm";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { CruiseTypeBookingForm } from "../../components/CruiseTypeBookingForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FilterTypes = () => {
  const { cruiseTypeUrl } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const whereToGoInputRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [cruiseId, setCruiseId] = useState(null);
  console.log(cruiseId);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const durationID = queryParams.get("durationID");
  const opreationDate = queryParams.get("opreationDate");
  const numberRooms = queryParams.get("numberRooms");
  const adultNumber = queryParams.get("adultNumber");
  const childNumber = queryParams.get("childNumber");
  const childAgesParam = queryParams.get("childAges");
  const { cruiseTypeListing, loadingCruisesList, errorCruisesList } =
    useSelector((state) => state.cruiseTypeListing);
  const [selectedItinerary, setSelectedItinerary] = useState([]); // State to hold the selected itinerary
  const [PageNumber, setPageNumber] = useState(1);
  const [CruiseLocationId, setCruiseLocationId] = useState([]); // Updated to handle multiple IDs
  const [CruiseTypeIds, setCruiseTypeIds] = useState([cruiseTypeUrl]); // Updated to handle multiple IDs
  const [CruiseBrandIds, setCruiseBrandIds] = useState([]); // Updated to handle multiple IDs
  const [CruiseRatingName, setCruiseRatingName] = useState([]); // Updated to handle multiple IDs
  const [SearchInput, setSearchInput] = useState(null); // Updated to handle multiple IDs

  const handlePagination = (index) => {
    setPageNumber(index);
    setTimeout(() => {
      document.getElementById("top").scrollIntoView({ behavior: "smooth" });
    }, 0); // Delay to ensure the element is rendered
  };
  const [isActiveMapFilter, setIsActiveMapFilter] = useState(false);

  useEffect(() => {
    const childAges = childAgesParam
      ? childAgesParam.split(",").map(Number)
      : [];

    const payload = {
      durationID,
      opreationDate: opreationDate,
      numberRooms,
      listPassengersRoomsRequests: [
        {
          adultNumber,
          childNumber,
          childAges,
        },
      ],
    };
    if (SearchInput && SearchInput !== null) {
      const timeOut = setTimeout(() => {
        if (SearchInput) {
          dispatch(
            fetchCruiseTypeListing({
              SearchInput,
              PageNumber,
              CruiseLocationId,
              cruiseTypeUrl,
              CruiseBrandIds,
              CruiseRatingName,
            })
          );
        }
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      dispatch(
        fetchCruiseTypeListing({
          SearchInput,
          PageNumber,
          CruiseLocationId,
          cruiseTypeUrl,
          CruiseBrandIds,
          CruiseRatingName,
        })
      );
    }
  }, [
    dispatch,
    SearchInput,
    cruiseTypeUrl,
    CruiseBrandIds,
    CruiseRatingName,
    CruiseLocationId,
    PageNumber,
    durationID,
    opreationDate,
    numberRooms,
    adultNumber,
    childNumber,
    childAgesParam,
  ]);

  // const handleCruiseClick = useCallback(
  //   async (operationID) => {
  //     const payload = {
  //       durationID: cruiseTypeListing.request?.durationID,
  //       opreationDate: cruiseTypeListing.request?.opreationDate,
  //       numberRooms: cruiseTypeListing.request?.numberRooms,
  //       listPassengersRoomsRequests: [
  //         {
  //           adultNumber:
  //             cruiseTypeListing.request?.listPassengersRoomsRequests[0]
  //               .adultNumber,
  //           childNumber:
  //             cruiseTypeListing.request?.listPassengersRoomsRequests[0]
  //               .childNumber,
  //           childAges:
  //             cruiseTypeListing.request?.listPassengersRoomsRequests[0]
  //               .childAges,
  //         },
  //       ],
  //     };

  //     dispatch(setSelectedCruiseId(operationID));

  //     // Create query string from payload
  //     const queryString = new URLSearchParams({
  //       operationID: operationID,
  //       durationID: payload.durationID,
  //       opreationDate: payload.opreationDate,
  //       numberRooms: payload.numberRooms,
  //       adultNumber: payload.listPassengersRoomsRequests[0].adultNumber,
  //       childNumber: payload.listPassengersRoomsRequests[0].childNumber,
  //       childAges: payload.listPassengersRoomsRequests[0].childAges.join(","),
  //     }).toString();

  //     try {
  //       const resultAction = await dispatch(
  //         fetchCruiseDetails({ operationID, payload })
  //       );
  //       if (fetchCruiseDetails.fulfilled.match(resultAction)) {
  //         navigate(`/cruises-details?${queryString}`);
  //       } else {
  //         throw new Error(resultAction.error.message);
  //       }
  //     } catch (err) {
  //       alert(err.message || "An error occurred while searching");
  //     }
  //   },
  //   [
  //     cruiseTypeListing.request?.durationID,
  //     cruiseTypeListing.request?.listPassengersRoomsRequests,
  //     cruiseTypeListing.request?.numberRooms,
  //     cruiseTypeListing.request?.opreationDate,
  //     dispatch,
  //     navigate,
  //   ]
  // );
  const handleViewItinerary = (operationID) => {
    // handleCruiseClick(operationID);
    const selectedCruise = cruiseTypeListing.response.find(
      (cruise) => cruise.operationID === operationID
    );
    setSelectedItinerary(selectedCruise?.listItineraryDays || []);
    setIsActiveMapFilter(true); // Show the itinerary filter
  };
  const [isExpanded, setIsExpanded] = useState(false); // Add this line at the top of your component

  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Cruises List</title>
        <style type="text/css">
          {`
          .header .header-menu .menu li a{
          color:#051036;
          }
          .header .header-menu .menu .subnav>li>a {
          color:#051036 !important;
          }
          .is-sticky.bg-dark-1{
          background-color:#fff !important;
          }
          // .is-sticky .menu li a{
          // color:#fff !important;
          // }
          .ratio-15\\:9::before{
          padding-bottom:0 !important;}
            .description {
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.collapsed {
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;  
}
.expanded {
 display: block;
 -webkit-line-clamp: unset;
 -webkit-box-orient: unset;
 overflow: unset;
 text-overflow:unset; 
} 
 .searchMenu-loc__field.-is-active,.searchMenu-guests__field.-is-active{
position:fixed;top:auto;left:auto}
.rs-picker-popup.rs-picker-popup-date{
z-index:9999;
}
          `}
        </style>
      </Helmet>
      {/*banner */}

      <section className="masthead pt-90 pb-0 bg-light-2" id="top">
        <div className="container">
          <div className="row pt-90">
            <div className="col-12">
              <div className="text-center water-text">
                <Fade
                  className="x"
                  direction="down"
                  duration={1000}
                  delay={0}
                  damping={0.1}
                  cascade
                >
                  <h1 className="text-40 lg:text-40 md:text-30 text-white is-in-view  ">
                    {cruiseTypeListing.dtoCruises?.cruiseTypeName
                      ? cruiseTypeListing.dtoCruises?.cruiseTypeName
                      : ""}
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* booking engine  */}
      <div className="py-20 bg-light-2">
        <div className="container">
          {" "}
          {/* <BookingFormListing
            CruiseLocationId={CruiseLocationId}
            CruiseTypeIds={cruiseTypeUrl}
            CruiseBrandIds={CruiseBrandIds}
            CruiseRatingName={CruiseRatingName}
            SearchInput={SearchInput}
          /> */}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        fullWidth={true}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        sx={{ top: "-50%" }}
        aria-labelledby="responsive-dialog-title"
        scroll="body"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            {" "}
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              See Availability
            </Typography>{" "}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          className="pb-50"
          sx={{ maxHeight: "fit-content", bgcolor: "#eee", overflow: "hidden" }}
        >
          <DialogContentText className="text-danger mt-5 mb-0">
            <p className="text-danger text-center mt-5 mb-0">
              {" "}
              To see available rooms and prices please enter duration and
              check-in date.
            </p>
          </DialogContentText>
          <CruiseTypeBookingForm cruiseId={cruiseId} ref={whereToGoInputRef} />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions> */}
      </Dialog>
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-content-between y-gap-30">
            <div
              className="col-xl-3 h-100"
              style={{ boxShadow: !loadingCruisesList && "0 0 2px #ccc" }}
            >
              <FilterLeftSide
                cruiseTypeListing={cruiseTypeListing}
                setCruiseLocationId={null}
                setCruiseBrandIds={setCruiseBrandIds}
                setCruiseRatingName={setCruiseRatingName}
                setSearchInput={setSearchInput}
              />
            </div>
            <div className="col-xl-9">
              {!loadingCruisesList && (
                <div className="row y-gap-10 items-center justify-between">
                  <div className="col-auto">
                    <div className="text-18">
                      <span className="fw-500">
                        {cruiseTypeListing?.numberOfCurises > 1
                          ? `${cruiseTypeListing?.numberOfCurises} Cruises found`
                          : `${cruiseTypeListing?.numberOfCurises} Cruise found`}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="row x-gap-20 y-gap-20">
                      <div className="col-auto">
                        <button className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
                          <i className="icon-up-down text-14 mr-10"></i>
                          Top picks ___ your search
                        </button>
                      </div>

                      <div className="col-auto d-none lg:d-block">
                        <button
                          data-x-click="filterPopup"
                          className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1"
                        >
                          <i className="icon-up-down text-14 mr-10"></i>
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* cruise Card  */}
              {loadingCruisesList ? (
                <LoaderInternal />
              ) : (
                <div className="row y-gap-30 mt-10 ">
                  <Fade
                    cascade
                    triggerOnce
                    duration={1000}
                    delay={0}
                    direction="right"
                    damping={0.1}
                  >
                    {cruiseTypeListing.dtoCruises?.cruises?.length > 0 ? (
                      cruiseTypeListing.dtoCruises?.cruises?.map(
                        (cruise, index) => (
                          <div className="col-12" key={cruise.cruiseID}>
                            <div className="border-top-light py-20">
                              <div className="row align-content-center  x-gap-20 y-gap-20">
                                <div className="col-md-auto cruiseCard">
                                  {/* Gallery  */}{" "}
                                  <div
                                    className="f54c1d70e0"
                                    data-testid="gallery-ribbon"
                                  >
                                    <div className="aaa3a3be2e">Top Rated</div>
                                    {/* <span className="cgh-ribbon__tails"></span> */}
                                  </div>
                                  <div
                                    className="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4"
                                    style={{ height: "200px" }}
                                  >
                                    <div
                                      className="cardImage__content"
                                      style={{ height: "100%" }}
                                    >
                                      <Swiper
                                        style={{ height: "100%" }}
                                        modules={[
                                          Pagination,
                                          Navigation,
                                          Autoplay,
                                        ]}
                                        autoplay={{
                                          delay: 3000,
                                          loop: false,
                                          delayTimeout: 1000,
                                          disableOnInteraction: false, // Continue autoplay after user interactions
                                        }}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation={{
                                          prevEl: `.js-prev-${index}`, // Custom previous button class
                                          nextEl: `.js-next-${index}`, // Custom next button class
                                        }} // Enable custom navigation
                                        pagination={{
                                          clickable: true,
                                          el: ".js-pagination", // Target custom pagination class
                                          renderBullet: (index, className) => {
                                            return `<div className="pagination__item ${className}" tabIndex="0" role="button" aria-label="Go to slide ${
                                              index + 1
                                            }"></div>`;
                                          },
                                        }}
                                      >
                                        {cruise.cruiseGalleryList.length > 0 ? (
                                          cruise.cruiseGalleryList.map(
                                            (image, index) => (
                                              <SwiperSlide
                                                key={index}
                                                style={{ height: "100%" }}
                                              >
                                                <img
                                                  style={{ height: "100%" }}
                                                  className="col-12"
                                                  src={image}
                                                  alt={cruise.cruiseName}
                                                />
                                              </SwiperSlide>
                                            )
                                          )
                                        ) : (
                                          <SwiperSlide>
                                            <div className="no-image-found d-flex justify-center  ">
                                              <p className="text-danger">
                                                No image found
                                              </p>
                                            </div>
                                          </SwiperSlide>
                                        )}
                                      </Swiper>
                                      {/* Custom Pagination Element */}
                                      <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                                      {/* Custom Navigation Buttons */}
                                      {cruise.cruiseGalleryList.length > 1 ? (
                                        <Fragment>
                                          <div className="cardImage-slider__nav -prev">
                                            <button
                                              className={`button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev-${index}`}
                                              tabIndex="0"
                                              aria-label="Previous slide"
                                            >
                                              <i className="icon-chevron-left text-10"></i>
                                            </button>
                                          </div>
                                          <div className="cardImage-slider__nav -next">
                                            <button
                                              className={`button -blue-1 bg-white size-30 rounded-full shadow-2 js-next-${index}`}
                                              tabIndex="0"
                                              aria-label="Next slide"
                                            >
                                              <i className="icon-chevron-right text-10"></i>
                                            </button>
                                          </div>
                                        </Fragment>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md">
                                  <h3 className="text-18 lh-16 fw-500">
                                    {cruise.cruiseName}
                                  </h3>{" "}
                                  <span className="text-15 text-secondary lh-12 fw-500">
                                    ({cruise.cruiseLocationNameSys})
                                  </span>
                                  <div
                                    className="d-inline-block ml-10"
                                    dangerouslySetInnerHTML={{
                                      __html: cruise.ratingStarCode,
                                    }}
                                  />
                                  {/* <div className="row x-gap-10 y-gap-10 items-center pt-2">
                                    <div className="col-auto">
                                      <p className="text-14">
                                        {cruise.itinaryNameSys}{" "}
                                      </p>
                                    </div>

                                    <div className="col-auto">
                                      <button
                                        onClick={() =>
                                          handleViewItinerary(
                                            cruise.operationID
                                          )
                                        }
                                        className="d-block text-14 text-blue-1 underline"
                                      >
                                        View Itinerary{" "}
                                      </button>
                                    </div>
                                  </div> */}
                                  <div className="text-14 lh-15 mt-10">
                                    <div
                                      className={`description ${
                                        isExpanded ? "expanded" : "collapsed"
                                      }`}
                                      dangerouslySetInnerHTML={{
                                        __html: cruise.cruiseDescription,
                                      }}
                                    />
                                    <button
                                      onClick={() => setIsExpanded(!isExpanded)}
                                      className="text-blue-1 underline"
                                    >
                                      {isExpanded ? "View Less" : "View More"}
                                    </button>
                                  </div>
                                  {cruise.cruiseFreeCancelation && (
                                    <div className="text-14 text-green-2 lh-15 mt-10">
                                      <div className="fw-500">
                                        Free cancellation{" "}
                                      </div>
                                      <div className="">
                                        You can cancel after{" "}
                                        {cruise.cruiseFreeCancelation}.{" "}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-auto text-right d-flex flex-column justify-content-between align-content-between md:text-left">
                                  <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                                    <div className="col-auto">
                                      <div className="text-14  lh-14 fw-500">
                                        {cruise.cruiseBrandNameSys}{" "}
                                      </div>
                                      <div className="text-14 lh-14 text-light-1">
                                        {cruise.numberOfDecks}{" "}
                                        {cruise.numberOfDecks > 1
                                          ? "Decks"
                                          : "Deck"}{" "}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    {/* <div className="text-14 text-light-1 mt-30 md:mt-30">
                                      {cruise.durationNameSys},{" "}
                                      {
                                        cruiseTypeListing.request
                                          ?.listPassengersRoomsRequests[0]
                                          .adultNumber
                                      }{" "}
                                      {cruiseTypeListing.request
                                        ?.listPassengersRoomsRequests[0]
                                        .adultNumber > 1
                                        ? "Adults"
                                        : "Adult"}
                                    </div>
                                    <div className="text-20  lh-12 fw-600 mt-5">
                                      {cruise.currencyCode}
                                      {cruise.totalPrice}
                                    </div>
                                    <div className="text-14 text-light-1 mt-5">
                                      +US$828 taxes and charges{" "}
                                    </div> */}

                                    <Button
                                      type="button"
                                      variant="outlined"
                                      onClick={() => {
                                        setCruiseId(cruise.cruiseID); // Set cruiseId to cruise.cruiseID
                                        handleClickOpen();
                                      }}
                                    >
                                      See Availability{" "}
                                      <div className="icon-arrow-top-right ml-15"></div>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="no-image-found d-flex justify-center  ">
                        <p className="text-danger my-5">
                          No cruises available for this options, change your
                          selections and try again...
                        </p>
                      </div>
                    )}
                  </Fade>
                </div>
              )}
              <div className="border-top-light   pt-30">
                <div className="row x-gap-10 y-gap-20 justify-between md:justify-center">
                  <div className="row x-gap-20 justify-content-between y-gap-20 items-center ">
                    <div className="col-auto ">
                      <button
                        onClick={() =>
                          PageNumber > 1 && handlePagination(PageNumber - 1)
                        }
                        disabled={PageNumber <= 1 ? true : false}
                        className="button -blue-1 size-40 rounded-full border-light"
                      >
                        <i className="icon-chevron-left text-12"></i>
                      </button>
                    </div>

                    <div className="col-auto d-flex">
                      {" "}
                      <div className="col-auto">
                        <button
                          className={`size-40 flex-center rounded-full bg-blue-1 text-white  `}
                          style={{ boxShadow: "0 0 2px #fcfcfc" }}
                          // onClick={() => handlePagination(index + 1)}
                        >
                          {/* {PageNumber} */}
                          {cruiseTypeListing?.pageIndex}
                        </button>
                      </div>
                      {cruiseTypeListing?.totalPages > 1 ? (
                        <Fragment>
                          <div className="col-auto">
                            <div className="size-40 flex-center rounded-full">
                              ...{" "}
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="size-40 flex-center rounded-full">
                              {cruiseTypeListing?.totalPages}
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>

                    <div className="col-auto ">
                      <button
                        onClick={() =>
                          cruiseTypeListing?.totalPages > PageNumber &&
                          handlePagination(PageNumber + 1)
                        }
                        disabled={
                          PageNumber >= cruiseTypeListing?.totalPages
                            ? true
                            : false
                        }
                        className="button -blue-1 size-40 rounded-full border-light"
                      >
                        <i className="icon-chevron-right text-12"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* filter  */}
      {/* {selectedItinerary && (
        <div className={`mapFilter ${isActiveMapFilter && "-is-active"}`}>
          <div
            className="mapFilter__overlay"
            onClick={() => setIsActiveMapFilter(false)}
          ></div>

          <div className="mapFilter__close">
            <button
              className="button -red-1 size-40 bg-white shadow-2"
              onClick={() => setIsActiveMapFilter(false)}
            >
              <i className="icon-close text-15"></i>
            </button>
          </div>

          <div
            className="mapFilter__grid row d-flex justify-content-between px-0 py-0"
            data-x="mapFilter__grid"
            data-x-toggle="-filters-hidden"
          >
            {" "}
            <div
              className="mapFilter-filter scroll-bar-1 col-lg-8"
              style={{
                scrollbarWidth: "thin",
                scrollbarColor: "#1976d2 transparent",
              }}
            >
              <div className="px-20 py-20 md:px-15 md:py-15">
                <h2 className="text-22 fw-500">
                  Itinerary Map
                  <br />{" "}
                </h2>
                <div className="mapFilter-filter__item">
                  <div className="row y-gap-20">
                    <div className="col-lg-12">
                      <div className="accordion -map row y-gap-20">
                        {selectedItinerary?.map((itinerary, index) => (
                          <div className="col-12" key={index}>
                            <div
                              className={`accordion__item  ${
                                activeFaq === index ? "is-active" : ""
                              }`}
                            >
                              <div className="d-flex">
                                <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
                                  <div className="text-14 fw-500 ">
                                    {itinerary.dayNumber}
                                  </div>
                                </div>

                                <div className="ml-30 w-100">
                                  <div className="text-16 lh-15 fw-500 text-blue-1">
                                    {itinerary.dayTitleName}
                                  </div>
                                   

                                  {activeFaq === index && (
                                    <div
                                      className="accordion__content"
                                      style={{
                                        maxHeight: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="d-flex justify-content-start align-items-center pt-15 pb-15">
                                        <img
                                          src={itinerary.dayPhot}
                                        
                                          alt={itinerary.dayTitleName}
                                          className="rounded-4 mt-15"
                                        />
                                        <div
                                          className="text-14 lh-17 ms-5 mt-15"
                                          dangerouslySetInnerHTML={{
                                            __html: itinerary.dayDetails,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="accordion__button">
                                    <button
                                      style={{
                                        margin: activeFaq === index && "auto",
                                      }}
                                      type="button"
                                      className="d-block lh-15 text-14     fw-500 mt-5"
                                      onClick={() => toggleAccordion(index)}
                                    >
                                      {activeFaq === index ? (
                                        <>
                                          <FontAwesomeIcon
                                            className="text-14 text-blue-1"
                                            icon={faAngleUp}
                                          />{" "}
                                          <br />
                                          <span className="text-14 text-blue-1">
                                            See less
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="text-14 text-secondary">
                                            See More & View photo
                                          </span>{" "}
                                          <FontAwesomeIcon
                                            className="text-14 text-secondary"
                                            icon={faAngleDown}
                                          />
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr style={{ borderTop: "1px solid #f5f5f5" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mapFilter-map col-lg-4   p-0"
              style={{ position: "absolute", right: 0 }}
            >
               
              <img
                alt={
                  cruiseTypeListing?.response?.length > 0
                    ? cruiseTypeListing?.response[0]?.itinaryNameSys
                    : ""
                }
                src={
                  cruiseTypeListing?.response?.length > 0
                    ? cruiseTypeListing?.response[0]?.itineraryMap
                    : "map-7days-NC.jpg"
                }
              />
            </div>
          </div>
        </div>
      )} */}
    </Fragment>
  );
};
export default FilterTypes;
