import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

const initialState = {
  confirmationBooking: {},
  loading: false,
  error: null,
};

// Async thunk action

export const fetchConfirmationBooking = createAsyncThunk(
  "confirmationBooking/fetchConfirmationBooking",
  async (payload) => {
    const response = await fetch(
      "https://api.nilecabins.info/be/BookingOrder",
      {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch extras");
    }
    return await response.json();
  }
);

const confirmationBookingSlice = createSlice({
  name: "confirmationBooking",
  initialState,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchConfirmationBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConfirmationBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.confirmationBooking = action.payload;
      })
      .addCase(fetchConfirmationBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default confirmationBookingSlice.reducer;
