import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "./url";

// Get initial state from localStorage if available

const initialState = {
  cruiseTypeListing: [],
  loadingCruisesList: false,
  errorCruisesList: null, // Store the error message
};

export const fetchCruiseTypeListing = createAsyncThunk(
  "cruiseTypeListing/fetchCruiseTypeListing",
  async ({
    PageNumber,
    CruiseLocationId,
    cruiseTypeUrl,
    CruiseBrandIds,
    CruiseRatingName,
    SearchInput,
  }) => {
    // Store payload in localStorage before making the request
    // localStorage.setItem("lastSearchPayload", JSON.stringify(payload));

    const response = await axios.get(
      // `${Url}/ws/getCruiseList?CruiseLocationId=&CruiseBrandId=&CruisesTypeId=&RatingStarName=&Search=&PageNumber=`
      `https://api.nilecabins.info/ws/getCruises?CruiseLocationId=${CruiseLocationId}&CruiseBrandId=${CruiseBrandIds}&CruiseTypeUrl=${cruiseTypeUrl}&RatingStarName=${CruiseRatingName}&Search=${SearchInput}&PageNumber=${PageNumber}`
    );
    return { data: response.data };
  }
);

const cruiseTypeListingSlice = createSlice({
  name: "cruiseTypeListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseTypeListing.pending, (state) => {
        state.loadingCruisesList = true;
      })
      .addCase(fetchCruiseTypeListing.fulfilled, (state, action) => {
        state.loadingCruisesList = false;
        state.cruiseTypeListing = action.payload.data;
        state.errorCruisesList = null;
      })
      .addCase(fetchCruiseTypeListing.rejected, (state, action) => {
        state.loadingCruisesList = false;
        state.errorCruisesList = action.error.message;
      });
  },
});

export default cruiseTypeListingSlice.reducer;
