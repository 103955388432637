import React, { Fragment, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider, Tooltip } from "@mui/material";
// import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchThanksData } from "../data/groupBooking/getThanksPageSlice";
import Loader from "../Loader";

const BookingConfirmation = () => {
  const navigate = useNavigate();
  const orderConfirmationNumber = localStorage.getItem(
    "orderConfirmationNumber"
  );
  const dispatch = useDispatch();
  const { thanksData, loading, error } = useSelector(
    (state) => state.thanksData
  );
  useEffect(() => {
    if (orderConfirmationNumber) {
      dispatch(fetchThanksData(orderConfirmationNumber))
        .unwrap()
        .catch((error) => {
          console.error("Failed to refetch cruise list:", error);
        });
    }
  }, [dispatch, orderConfirmationNumber]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        error
      ) : (
        <Fragment>
          <Helmet>
            <title>Booking Confirmation</title>{" "}
            <style>
              {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
            </style>
          </Helmet>
          {/*banner */}
          <section className="row d-flex items-center  mt-90  ">
            <div className="  mt-40 py-40 bg-light-2 border-top-light">
              <div className="container d-flex justify-content-between m-auto px-1    y-gap-30 items-center ">
                <div className="col-auto pe-1">
                  <Tooltip arrow title="Return To Choose Cruise">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-4)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cruise
                      </div>
                    </div>
                  </Tooltip>
                </div>{" "}
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <div className="col-auto  px-1">
                  <Tooltip arrow title="Return To Choose Cabin">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-3)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cabin
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <Fragment>
                  <div className="col-auto  px-1">
                    <Tooltip arrow title="Return To Select Extras">
                      <div
                        className="d-flex items-center"
                        onClick={() => navigate(-2)}
                        style={{ cursor: "pointer" }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "#1976d2";
                        }}
                      >
                        <div className="size-40 rounded-full flex-center bg-blue-1">
                          <i className="icon-check text-16 text-white"></i>
                        </div>
                        <div className="text-18 text-blue-1 fw-500 ml-10">
                          Extras selection
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col px-1">
                    <div className="w-full h-1 border-blue-1 bg-border"></div>
                  </div>
                </Fragment>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div className="size-40 rounded-full flex-center bg-blue-1">
                      <i className="icon-check text-16 text-white"></i>
                    </div>
                    <div className="text-18 text-blue-1 fw-500 ml-10">
                      Checkout{" "}
                    </div>
                  </div>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div className="size-40 text-16 text-white rounded-full flex-center bg-blue-1">
                      5
                    </div>
                    <div className="text-18 text-center text-blue-1 fw-500 ml-10">
                      Booking Completed!
                      <br />
                      (Thank You)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" layout-pb-md">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {thanksData?.paymentStatus == "SUCCESS" ? (
                    <div className="d-flex flex-column items-center mt-60 lg:md-40 sm:mt-24">
                      <div className="size-80 flex-center text-white rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-30 text-white"></i>
                      </div>

                      <div className="text-30 lh-1 fw-600 mt-20  text-main">
                        Payment Successfully Paid!{" "}
                      </div>
                      <div className="text-15 text-light-1 mt-10 col-lg-6 text-center">
                        Thank you for your payment. Your transaction has been
                        successfully completed. Booking details has been sent
                        to:{" "}
                        <span className="text-primary">
                          {thanksData?.customerEmailAddress}
                        </span>{" "}
                      </div>
                    </div>
                  ) : null}

                  <div className="border-type-1 rounded-8 px-50 py-35 mt-40">
                    <div className="row justify-content-between text-center">
                      <div className="col-lg-3 col-md-6">
                        <div className="text-15 lh-12 text-main">
                          Order Number{" "}
                        </div>
                        <div className="text-15 lh-12 fw-500 text-blue-1 mt-10 ">
                          {thanksData?.orderConfirmationNumber}{" "}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="text-15 lh-12 text-main">
                          Order Date{" "}
                        </div>
                        <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                          {thanksData?.orderDateTime}{" "}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="text-15 lh-12 text-main">Total </div>
                        <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                          ${thanksData?.totalPrice}
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="text-15 lh-12 text-main">
                          Payment Status{" "}
                        </div>
                        <div className="text-15 lh-12 fw-500 text-blue-1 mt-10">
                          {thanksData?.paymentStatus}{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-light rounded-8 px-50 py-40 mt-40">
                    <h4 className="text-20 fw-500 mb-30">Your Information </h4>

                    <div className="row y-gap-10 mb-20">
                      <div className="col-12">
                        <div className="d-flex justify-between ">
                          <div className="text-15 lh-16">Name </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.customerName}{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Email </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.customerEmailAddress}{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Phone </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.customerMobile}{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Nationality </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.nationality}
                          </div>
                        </div>
                      </div>
                      {thanksData?.special_Requirement ? (
                        <div className="col-12">
                          <div className="d-flex justify-between border-top-light pt-10">
                            <div className="text-15 lh-16">
                              Special Requirement{" "}
                            </div>
                            <div className="text-15 lh-16 fw-500 text-blue-1">
                              {" "}
                              {thanksData?.special_Requirement}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <h4 className="text-20 fw-500 mb-30">
                      Booking Information{" "}
                    </h4>
                    <div className="row y-gap-10 mb-20">
                      <div className="col-12">
                        <div className="d-flex justify-between ">
                          <div className="text-15 lh-16">Cruise Name </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.cruiseName}{" "}
                          </div>
                        </div>
                      </div>
                      {thanksData?.cabins?.map((cabin, index) => (
                        <div className="col-12" key={index}>
                          <div className="d-flex justify-between border-top-light pt-10">
                            <div className="text-15 lh-16">Cabin Name </div>
                            <div className="text-15 lh-16 fw-500 text-blue-1">
                              {cabin?.cabinName}{" "}
                            </div>
                          </div>
                        </div>
                      ))}
                      {thanksData?.cabins?.map((cabin, index) => (
                        <div className="col-12" key={index}>
                          <div className="d-flex justify-between border-top-light pt-10">
                            <div className="text-15 lh-16">Cabin Number </div>
                            <div className="text-15 lh-16 fw-500 text-blue-1">
                              {thanksData?.cabins?.length} Cabin
                              {thanksData?.cabins?.length > 1 ? "s" : ""}
                            </div>
                          </div>
                        </div>
                      ))}
                      {thanksData?.cabins?.map((cabin, index) => (
                        <div className="col-12" key={index}>
                          <div className="d-flex justify-between border-top-light pt-10">
                            <div className="text-15 lh-16">Adults Number</div>
                            <div className="text-15 lh-16 fw-500 text-blue-1">
                              {cabin.adultsNumber} Adult
                              {cabin.adultsNumber > 1 ? "s" : ""}
                            </div>
                          </div>
                        </div>
                      ))}
                      {thanksData?.cabins?.map((cabin, index) => (
                        <div className="col-12" key={index}>
                          <div className="d-flex justify-between border-top-light pt-10">
                            <div className="text-15 lh-16">Child Number</div>
                            <div className="text-15 lh-16 fw-500 text-blue-1">
                              {cabin.childNumber} Child
                              {cabin.childNumber > 1 ? "s" : ""}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Extras</div>
                          <div className="text-15 text-right lh-16 fw-500 text-blue-1">
                            {thanksData?.extras?.map((extra, index) => (
                              <span key={index}>
                                ({extra.extraName}) ${extra.priceExtraTotal}
                                <br />
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h4 className="text-20 fw-500 mb-30">
                      Payment Information{" "}
                    </h4>
                    <div className="row y-gap-10 mb-20">
                      <div className="col-12">
                        <div className="d-flex justify-between ">
                          <div className="text-15 lh-16">Order Number </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.orderConfirmationNumber}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Order Date </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.orderDateTime}{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Payment Status </div>
                          <div className="text-15 lh-16 fw-500 text-blue-1">
                            {thanksData?.paymentStatus}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-between border-top-light pt-10">
                          <div className="text-15 lh-16">Taxes </div>
                          <div className="text-15 text-right lh-16 fw-500 text-blue-1">
                            {thanksData?.taxes?.map((i, index) => (
                              <span key={index}>
                                ({i.basePercentage}%) ${i.priceTax}
                                <br />
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex justify-center align-items-center border-top-light pt-10">
                          <h3 className="text-18 lh-16 me-3">Total Price: </h3>
                          <h3 className="text-18 lh-16 fw-500 text-blue-1">
                            ${thanksData?.totalPrice}{" "}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BookingConfirmation;
