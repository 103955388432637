import {
  faAngleRight,
  faBookmark,
  faCalendarXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Popover, Whisper } from "rsuite";

export const Seat = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  return (
    <>
      <Helmet>
        <title>Special Offers</title>
        <style>
          {`.layout-pt-lg{
		padding-top: 200px;
			padding-bottom: 150px;
  }
  `}
        </style>
      </Helmet>
      <section
        className="section-bg layout-pt-lg layout-pb-lg"
        style={{
          backgroundImage: "url(/TITSolutions/img/new/bg/bg2.jpeg)",

          backgroundSize: isMobile ? "cover" : "cover",
          backgroundPosition: "center 25%",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <div className="section-bg__item overlay col-12">
          <img src="/TITSolutions/img/new/bg/bg-1.jpg" alt="banner" />
        </div> */}

        <div className="container">
          <div className="row justify-left text-start">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <h1 className="text-40 md:text-25 fw-600 text-white py-30">
                Seats{" "}
              </h1>
              {/* <div className="col-lg-3 mt-3">
                <Link
                  to="/become-partner"
                  className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                >
                  Join Us <div className="icon-arrow-top-right ps-2"></div>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex items-center">
        <div className="container   py-20 border-bottom-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div>
                    <FontAwesomeIcon
                      className="text-10 text-light-1"
                      icon={faAngleRight}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">Seats </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-primary">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>{" "}
      <div class=" layout-pt-md layout-pb-lg">
        <div class="container">
          <div
            class="screen-area position-relative"
            style={{ position: "relative" }}
          >
            <div className="row y-gap-20 justify-center items-center">
              <div className="col-auto">
                <div className="row text-center x-gap-20 y-gap-10 items-center">
                  <div className="sectionTitle  text-center mx-auto  ">
                    <h2 className="sectionTitle__title mb-3 text-center">
                      {" "}
                      Select Your Seat From Deck Plan
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="screen-thumb text-center">
              <img src="assets/images/movie/screen-thumb.png" alt="movie" />
            </div>

            <div class="screen-wrapper">
              <ul class="seat-area">
                <li class="seat-line">
                  <span>D</span>
                  <ul class="seat--area">
                    <li class="front-seat">
                      <ul>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                      </ul>
                    </li>

                    <li class="front-seat">
                      <ul>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <span>D</span>
                </li>
                <li class="seat-line">
                  <span>B</span>
                  <ul class="seat--area">
                    <li class="front-seat">
                      <ul>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2578" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2578</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2576" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />

                            <span class="sit-num text-10">2576</span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2572" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2572</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2570" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2570</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Out The Service"
                                className="text-warning"
                              ></Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                            />
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Out The Service"
                                className="text-warning"
                              ></Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                            />
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2564" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2564</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2562" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2562</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2560" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2560</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2558" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2558</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2556" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2556</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2554" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2554</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2552" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2552</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2550" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2550</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2548" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2548</span>
                          </Whisper>
                        </li>{" "}
                      </ul>
                    </li>

                    <li class="front-seat">
                      <ul>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2546" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2546</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2544" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary ">
                                  <span className="text-decoration-line-through text-secondary">
                                    200$
                                    <br />
                                  </span>{" "}
                                  160$
                                </p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01.png"; // Toggle the source
                              }}
                            />{" "}
                            <span class="sit-num text-light px-1    bg-warning text-10">
                              {" "}
                              20%off
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2542" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2542</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2540" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2540</span>
                          </Whisper>
                        </li>{" "}
                      </ul>
                    </li>
                  </ul>
                  <span>B</span>
                </li>
              </ul>
            </div>
            <img
              src="assets/IDP-DECK02.svg"
              alt="deck plan"
              className="deckplan-image"
            />
            <div
              class="screen-wrapper   "
              style={{ marginTop: isMobile ? 0 : "50vh" }}
            >
              <ul class="seat-area">
                <li class="seat-line">
                  <span>C</span>
                  <ul class="seat--area">
                    <li class="front-seat">
                      <ul>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                      </ul>
                    </li>

                    <li class="front-seat">
                      <ul>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <span>C</span>
                </li>
                <li class="seat-line">
                  <span>B</span>
                  <ul class="seat--area">
                    <li class="front-seat">
                      <ul>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2578" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2578</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2576" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />

                            <span class="sit-num text-10">2576</span>
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Not Allowed"
                                className="text-danger"
                              >
                                <p>This Seat Is Booked</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              style={{ cursor: "not-allowed" }}
                            />
                            <span class="sit-num">
                              <FontAwesomeIcon
                                className="text-danger  "
                                icon={faCalendarXmark}
                              />
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2572" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2572</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2570" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2570</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Out The Service"
                                className="text-warning"
                              ></Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                            />
                          </Whisper>
                        </li>
                        <li class="single-seat">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover
                                title="Out The Service"
                                className="text-warning"
                              ></Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                            />
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2564" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2564</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2562" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2562</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2560" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2560</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2558" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2558</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2556" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2556</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2554" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2554</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2552" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2552</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2550" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2550</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2548" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2548</span>
                          </Whisper>
                        </li>{" "}
                      </ul>
                    </li>

                    <li class="front-seat">
                      <ul>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2546" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2546</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2544" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary ">
                                  <span className="text-decoration-line-through text-secondary">
                                    200$
                                    <br />
                                  </span>{" "}
                                  160$
                                </p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01.png"; // Toggle the source
                              }}
                            />{" "}
                            <span class="sit-num text-light px-1    bg-warning text-10">
                              {" "}
                              20%off
                            </span>
                          </Whisper>
                        </li>
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2542" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2542</span>
                          </Whisper>
                        </li>{" "}
                        <li class="single-seat seat-free">
                          <Whisper
                            placement="top"
                            trigger="hover"
                            controlId="control-id-hover"
                            speaker={
                              <Popover title="2540" className="text-main">
                                <p className="text-14 ">Book Now :</p>
                                <p className="text-14 text-primary">200$</p>
                              </Popover>
                            }
                          >
                            <img
                              src="assets/images/movie/seat01-free.png"
                              alt="seat"
                              onClick={(event) => {
                                const img = event.target; // Get the clicked image
                                img.src = img.src.includes("seat01-free.png")
                                  ? "./assets/images/movie/seat01-booked.png"
                                  : "assets/images/movie/seat01-free.png"; // Toggle the source
                              }}
                            />
                            <span class="sit-num text-10">2540</span>
                          </Whisper>
                        </li>{" "}
                      </ul>
                    </li>
                  </ul>
                  <span>B</span>
                </li>
              </ul>
              <h5 class="subtitle mt-20 mb-20" style={{ color: "#F27BA9" }}>
                Front Seats
              </h5>
              <ul class="seat-area couple" style={{ width: "100%" }}>
                <li class="seat-line">
                  <span>A</span>
                  <ul class="seat--area">
                    <li class="front-seat">
                      <ul>
                        {" "}
                        <li class="single-seat">
                          <img
                            src="assets/images/movie/seat02.png"
                            alt="seat"
                            onClick={(event) => {
                              const img = event.target; // Get the clicked image
                              img.src = img.src.includes("seat02.png")
                                ? "./assets/images/movie/seat02-booked.png"
                                : "assets/images/movie/seat02.png"; // Toggle the source
                            }}
                          />{" "}
                          <span class="sit-num text-14">2233</span>
                        </li>
                        <li class="single-seat">
                          <img
                            src="assets/images/movie/seat02.png"
                            alt="seat"
                            onClick={(event) => {
                              const img = event.target; // Get the clicked image
                              img.src = img.src.includes("seat02.png")
                                ? "./assets/images/movie/seat02-booked.png"
                                : "assets/images/movie/seat02.png"; // Toggle the source
                            }}
                          />{" "}
                          <span class="sit-num text-14">2235</span>
                        </li>{" "}
                        <li class="single-seat">
                          <img
                            src="assets/images/movie/seat02.png"
                            alt="seat"
                            onClick={(event) => {
                              const img = event.target; // Get the clicked image
                              img.src = img.src.includes("seat02.png")
                                ? "./assets/images/movie/seat02-booked.png"
                                : "assets/images/movie/seat02.png"; // Toggle the source
                            }}
                          />{" "}
                          <span class="sit-num text-14">2237</span>
                        </li>{" "}
                        <li class="single-seat">
                          <img
                            src="assets/images/movie/seat02.png"
                            alt="seat"
                            onClick={(event) => {
                              const img = event.target; // Get the clicked image
                              img.src = img.src.includes("seat02.png")
                                ? "./assets/images/movie/seat02-booked.png"
                                : "assets/images/movie/seat02.png"; // Toggle the source
                            }}
                          />{" "}
                          <span class="sit-num text-14">2239</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <span>A</span>
                </li>
              </ul>
            </div>
          </div>
          <Button
            className="text-center d-flex justify-content-center mx-auto px-50 py-10"
            variant="contained"
          >
            {" "}
            Book Now{" "}
          </Button>
        </div>
      </div>
    </>
  );
};
