import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import "./cabins.css";
import { Accordion, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBed,
  faMaximize,
  faMinus,
  faRecycle,
  faRemove,
  faSquarePlus,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip, useMediaQuery } from "@mui/material";

import {
  fetchCruiseExtras,
  removeExtra,
  selectExtra,
} from "../../data/groupBooking/getExtraSlice";

import { fetchCheckOut } from "../../data/groupBooking/checkOutSlice";
import Loader from "../../Loader";

const AddExtra = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const durationID = queryParams.get("durationID");
  const opreationDate = queryParams.get("opreationDate");
  const numberRooms = queryParams.get("numberRooms");
  const adultNumber = queryParams.get("adultNumber");
  const childNumber = queryParams.get("childNumber");
  const childAgesParam = queryParams.get("childAges");
  const operationID = queryParams.get("operationID");
  const cruiseID = queryParams.get("cruiseID");
  const cbinID = queryParams.get("cbinID");
  const cruiseRateId = queryParams.get("cruiseRateId");

  const { extras, selectedExtras, loadingExtras, error } = useSelector(
    (state) => state.extras
  );

  useEffect(() => {
    const childAges = childAgesParam
      ? childAgesParam.split(",").map(Number)
      : [];
    const payload = {
      durationID: durationID,
      opreationDate: opreationDate,
      numberRooms,
      operatinID: operationID,
      cruiseID,
      cbinID,
      cruiseRateId,
      listPassengersRoomsRequests: [
        {
          adultNumber,
          childNumber,
          childAges,
        },
      ],
    };
    if (!extras?.response) {
      dispatch(fetchCruiseExtras(payload))
        .unwrap()
        .catch((error) => {
          console.error("Failed to refetch cruise list:", error);
        });
    }
  }, [
    adultNumber,
    childAgesParam,
    childNumber,
    extras?.response,
    dispatch,
    durationID,
    numberRooms,
    operationID,
    opreationDate,
    cruiseID,
    cbinID,
    cruiseRateId,
  ]);

  const [checkedExtras, setCheckedExtras] = useState({});
  const handleSelectExtra = (cruiseExtraID, checked) => {
    dispatch(selectExtra({ cruiseExtraID, checked }));
    setCheckedExtras((prev) => ({
      ...prev,
      [cruiseExtraID]: checked,
    }));
  };
  const handleRemoveExtra = (cruiseExtraID) => {
    dispatch(removeExtra(cruiseExtraID));
    setCheckedExtras((prev) => ({
      ...prev,
      [cruiseExtraID]: false,
    }));
  };
  const calculateTotalPrice = () => {
    return selectedExtras.reduce(
      (total, extra) => total + extra.cruiseExtraPrice,
      0
    );
  };
  const handleBookingClick = useCallback(async () => {
    if (extras) {
      const payload = {
        durationID: extras?.request?.durationID,
        opreationDate: extras?.request?.opreationDate,
        numberRooms: extras?.request?.numberRooms,
        listPassengersRoomsRequests: [
          {
            adultNumber:
              extras?.request?.listPassengersRoomsRequests[0].adultNumber,
            childNumber:
              extras?.request?.listPassengersRoomsRequests[0].childNumber,
            childAges:
              extras?.request?.listPassengersRoomsRequests[0].childAges,
          },
        ],
        operatinID: extras?.request?.operatinID,
        cruiseID: extras?.request?.cruiseID,
        cbinID: extras.request?.cbinID,
        cruiseRateId: extras.request?.cruiseRateId,
        extrasIds: selectedExtras?.map((extra) => extra.cruiseExtraID),
      };
      const queryString = new URLSearchParams({
        operationID: payload.operatinID,
        durationID: payload.durationID,
        opreationDate: payload.opreationDate,
        numberRooms: payload.numberRooms,
        adultNumber: payload.listPassengersRoomsRequests[0].adultNumber,
        childNumber: payload.listPassengersRoomsRequests[0].childNumber,
        childAges: payload.listPassengersRoomsRequests[0].childAges.join(","),
        extrasIds: payload.extrasIds.join(","),
        cruiseID: payload.cruiseID,
        cbinID: payload.cbinID,
        cruiseRateId: payload.cruiseRateId,
      }).toString();
      try {
        const resultAction = await dispatch(fetchCheckOut(payload));
        if (fetchCheckOut.fulfilled.match(resultAction)) {
          navigate(`/checkout?${queryString}`);
        } else {
          throw new Error(resultAction.error.message);
        }
      } catch (err) {
        alert(err.message || "An error occurred while searching");
      }
      // dispatch(fetchCheckOut(payload));
      // navigate("/checkout");
    }
  }, [dispatch, extras, navigate, selectedExtras]);
  return (
    <Fragment>
      {loadingExtras ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Fragment>
          <Helmet>
            <title>Add Extra</title>
            <style>
              {`
          .header .header-menu .menu li a{
          color:#051036;
          }
          .header .header-menu .menu .subnav>li>a {
          color:#051036 !important;
          }
          .is-sticky.bg-dark-1{
          background-color:#fff !important;
          }
          // .is-sticky .menu li a{
          // color:#fff !important;
          // }
          .ratio-15\:9::before{
          padding-bottom:0 !important;}
          
          .form-checkbox__mark{
            width: 25px !important;
  height: 25px !important;
          }     
          `}
            </style>
          </Helmet>
          {/*banner */}
          <section className="row d-flex items-center  mt-90  ">
            <div className="  mt-40 py-40 bg-light-2 border-top-light">
              <div className="container d-flex justify-content-between m-auto px-1    y-gap-30 items-center ">
                <div className="col-auto pe-1">
                  <Tooltip arrow title="Return To Choose Cruise">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-2)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cruise
                      </div>
                    </div>
                  </Tooltip>
                </div>{" "}
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <div className="col-auto  px-1">
                  <Tooltip arrow title="Return To Choose Cabin">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-1)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cabin
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <Fragment>
                  <div className="col-auto px-1">
                    <div className="d-flex items-center">
                      <div className="size-40 rounded-full flex-center bg-blue-2">
                        <div className="size-40 rounded-full flex-center bg-blue-1">
                          <span className=" text-16 text-white">3</span>
                        </div>
                      </div>
                      <div className="text-18 fw-500 ml-10">
                        Extras selection
                      </div>
                    </div>
                  </div>

                  <div className="col px-1">
                    <div className="w-full h-1 bg-border"></div>
                  </div>
                </Fragment>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                      4
                    </div>
                    <div className="text-18 fw-500 ml-10">Checkout </div>
                  </div>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 bg-border"></div>
                </div>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                      5
                    </div>
                    <div className="text-18 fw-500 ml-10">
                      Booking Completed!{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* form */}
          <section
            className="card-area section--padding my-5"
            id="cabin-details"
          >
            <div className="container">
              <div className="d-flex flex-wrap justify-content-between col-12  mx-auto">
                <div className="col-12 col-lg-7 me-lg-5">
                  <h3 className="mb-3">Select extras for your stay</h3>
                  {extras?.response?.cruiseExtras?.length ? (
                    <div className="col-xs-12 fb-results-accommodation  mb-5 ">
                      <div className="col-xs-12 d-flex justify-content-between align-items-center fb-dark-bg fb-block-header fb-results-acc-title mt-0 ">
                        <span>
                          {extras.numberOfExtras}{" "}
                          {extras.numberOfExtras > 1
                            ? "Extras Available"
                            : "Extra Available"}
                        </span>
                      </div>

                      {extras?.response?.cruiseExtras?.map((extra) => (
                        <label
                          htmlFor={`select-${extra.cruiseExtraID}`}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #ccc",
                          }}
                          key={extra.cruiseExtraID}
                          className="d-flex justify-content-between align-items-start px-3 py-10"
                        >
                          {" "}
                          <div className="col-lg-3">
                            <img
                              src={extra.cruiseExtraPhoto}
                              alt={extra.cruiseExtraName}
                              loading="lazy"
                              className="me-2"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="room-content text-left me-auto border-0 pt-0 col-7">
                            <h5 className="fs-4 mt-0 pt-0">
                              {extra.cruiseExtraName}
                            </h5>

                            <div className="room-amenities-item">
                              <ul className="col-12 d-flex justify-content-between flex-wrap">
                                <p
                                  className="my-2"
                                  dangerouslySetInnerHTML={{
                                    __html: extra.cruiseExtraDescription,
                                  }}
                                />
                              </ul>
                            </div>

                            <span to="#." style={{ color: "#103682" }}>
                              <span
                                className="me-2  fw-bold col-3  "
                                style={{
                                  fontSize: "13px",
                                  color: "#000",
                                }}
                              >
                                Total Price:
                              </span>{" "}
                              <b>
                                {extra.currencyCode} {extra.cruiseExtraPrice}
                              </b>
                            </span>
                          </div>
                          <div className="   d-flex align-items-center justify-content-between position-relative">
                            <div
                              className="card-btn "
                              style={{ bottom: "unset" }}
                            >
                              <div className="form-checkbox d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  name="name"
                                  id={`select-${extra.cruiseExtraID}`}
                                  checked={
                                    checkedExtras[extra.cruiseExtraID] || false
                                  }
                                  onChange={(e) =>
                                    handleSelectExtra(
                                      extra.cruiseExtraID,
                                      e.target.checked
                                    )
                                  }
                                />
                                <div className="form-checkbox__mark">
                                  <div className="form-checkbox__icon icon-check"></div>
                                </div>
                                <div className="col-auto ms-2">
                                  <label
                                    htmlFor={`select-${extra.cruiseExtraID}`}
                                    className="text-16 text-light-1"
                                  >
                                    Select
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                  ) : (
                    <p className="text-danger">
                      No Extra Exist For This Cruise
                    </p>
                  )}
                </div>
                {/* Bookind Info  */}
                <div className="col-12 col-lg-4">
                  <div className="">
                    {/* Extras  */}
                    {selectedExtras?.length > 0 && (
                      <div className="form-box booking-detail-form mt-20  px-30 py-30 border-light rounded-4">
                        <div className="form-content">
                          <div className="card-item shadow-none radius-none mb-0">
                            <div className="card-body p-0">
                              <ul className="list-items list-items-2 py-0">
                                <li className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="me-2 text-main fw-bold  ">
                                    Extras
                                  </h6>
                                  <h6 className="me-2 fw-bold text-primary">
                                    ${calculateTotalPrice()}
                                  </h6>
                                </li>
                                <div className="w-full h-1 bg-border my-2 "></div>
                                {selectedExtras?.map((extra, index) => (
                                  <li
                                    key={extra.cruiseExtraID}
                                    className={`row d-flex justify-content-between align-items-center py-2 ${
                                      index % 2 === 1 && "bg-light"
                                    }`}
                                    style={{ borderBottom: "1px solid #ccc" }}
                                  >
                                    <span className="d-flex align-items-center me-2 col-lg-7">
                                      <FontAwesomeIcon
                                        icon={faSquarePlus}
                                        className="text-13 text-main pe-1"
                                      />

                                      <select
                                        // value={age}
                                        // onChange={(e) =>
                                        //   handleAgeChange(
                                        //     index,
                                        //     parseInt(e.target.value)
                                        //   )
                                        // }
                                        className="form-select  text-15 text-main fw-500"
                                      >
                                        {[...Array(10)].map((_, i) => (
                                          <option
                                            className="form-select text-main text-14"
                                            key={i + 1}
                                            value={i + 1}
                                          >
                                            {i + 1} {extra.cruiseExtraName}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                    <span className="me-2 fw-bold col-lg-3 text-center  text-primary">
                                      {extra.currencyCode}
                                      {extra.cruiseExtraPrice}
                                    </span>
                                    <button
                                      className="py-0 px-0 col-lg-1 text-light bg-danger"
                                      style={{
                                        display: "inline-block",

                                        float: "right",
                                        width: "20px",
                                        height: "20px",
                                        lineHeight: "1",
                                        borderRadius: "50%",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() =>
                                        handleRemoveExtra(extra.cruiseExtraID)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faMinus} />{" "}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="px-30 py-30 mt-20 border-light rounded-4">
                      <div className="row align-items-center x-gap-15 y-gap-20">
                        <div className="text-20 fw-500  mb-20">
                          Your booking details{" "}
                        </div>
                        <div className="col-auto">
                          <img
                            src={extras.response?.cruisePhoto}
                            alt={extras.response?.cruiseName}
                            loading="lazy"
                            className="size-120 rounded-4 object-cover"
                          />
                        </div>

                        <div className="col">
                          <div
                            className="d-flex x-gap-5 pb-10"
                            dangerouslySetInnerHTML={{
                              __html: extras.response?.curiseStarCode,
                            }}
                          />

                          <div className="text-20 lh-17 fw-500">
                            {extras.response?.cruiseName}{" "}
                          </div>
                          <div className="text-14 lh-15 mt-5">
                            {extras.response?.itineraryName}{" "}
                          </div>

                          {/* <div className="row x-gap-10 y-gap-10 items-center pt-10">
                            <div className="col-auto">
                              <div className="d-flex items-center">
                                <div className="size-30 flex-center bg-blue-1 rounded-4">
                                  <div className="text-12 fw-600 text-white">
                                    4.8{" "}
                                  </div>
                                </div>

                                <div className="text-14 fw-500 ml-10">
                                  Exceptional{" "}
                                </div>
                              </div>
                            </div>

                            <div className="col-auto">
                              <div className="text-14">3,014 reviews </div>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="row y-gap-20 justify-between">
                        <div className="col-auto">
                          <div className="text-15">Check-in </div>
                          <div className="fw-500">
                            {extras.response?.itineraryFrom}
                          </div>
                          {/* <div className="text-15 text-light-1">
                            15:00 – 23:00{" "}
                          </div> */}
                        </div>

                        <div className="col-auto md:d-none">
                          <div className="h-full w-1 bg-border"></div>
                        </div>

                        <div className="col-auto text-right md:text-left">
                          <div className="text-15">Check-out </div>
                          <div className="fw-500">
                            {extras.response?.itineraryTo}
                          </div>
                          {/* <div className="text-15 text-light-1">
                            01:00 – 11:00{" "}
                          </div> */}
                        </div>
                      </div>
                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="text-15 mb-10">You selected: </div>
                      <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto d-flex">
                          <img
                            style={{ borderRadius: "5px" }}
                            src={extras.response?.cabinData?.cabinPhoto}
                            alt=""
                            className="me-2"
                            width="50"
                          />{" "}
                          <div className="text-main fw-500 text-16">
                            {extras.response?.cabinData?.cabinName}
                            <br />
                            <span className="text-12 text-secondary">
                              {" "}
                              ({extras.response?.cabinData?.cabinTypeNameSys})
                            </span>
                          </div>
                        </div>

                        <div className="col-auto d-flex flex-column align-items-end justify-content-start">
                          <div className="col-auto d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({extras.response?.cabinData?.cabinBed}){" "}
                            </div>

                            <FontAwesomeIcon
                              icon={faBed}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                          <div className="col-auto  d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({extras.response?.cabinData?.cabinOccupancy}){" "}
                            </div>
                            <FontAwesomeIcon
                              icon={faUsersViewfinder}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                          <div className="col-auto  d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({extras.response?.cabinData?.cabinSize}
                              {
                                extras.response?.cabinData?.sizeMeasurement
                              }){" "}
                            </div>
                            <FontAwesomeIcon
                              icon={faMaximize}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-30 py-30 border-light rounded-4 mt-30">
                      <div className="text-20 fw-500 mb-20">
                        Your price summary{" "}
                      </div>
                      {extras.response?.taxes?.taxs?.map((item, index) => (
                        <div
                          key={index}
                          className="row y-gap-5 justify-between"
                        >
                          <div className="col-auto">
                            <div className="text-15">{item.taxName} </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-15">${item.taxValue} </div>
                          </div>
                        </div>
                      ))}

                      <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                        <div className="row y-gap-5 justify-between">
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">Price </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">
                              ${extras.response?.totalPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div
                    className="row  mt-3 text-center"
                    style={{ bottom: "unset" }}
                  >
                    <button
                      type="button"
                      onClick={handleBookingClick}
                      className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddExtra;
