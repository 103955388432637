import React, { useEffect, useState } from "react";
import { colors, Slider } from "@mui/material";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Checkbox } from "rsuite";
const labels = {
  1: "Useless",
  2: "Poor",

  3: "Ok",

  4: "Good",
  5: "Excellent",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function valuetext(value) {
  return `${value}°C`;
}

const FilterLeftSide = ({
  cruisesListBooking,
  setCruiseLocationId,
  setCruiseTypeIds,
  setCruiseBrandIds,
  setCruiseRatingName,
  setSearchInput,
}) => {
  // const [valuePrice, setValuePrice] = React.useState([20, 80]);

  // const [valueStar, setValueStar] = React.useState(3);
  // const [hover, setHover] = React.useState(-1);
  // const handlePriceChange = (event, newValue) => {
  //   setValuePrice(newValue);
  // };
  /*______________________________________________*/
  // Locations
  const [selectedCruiseLocationIds, setSelectedCruiseLocationIds] = useState(
    []
  );
  const [checked, setChecked] = useState(false);
  const handleCheckboxCruiseLocationChange = (id) => {
    setChecked(true);
    setSelectedCruiseLocationIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If not selected, add it
        return [...prevSelected, id];
      }
    });
  };
  useEffect(() => {
    if (checked) {
      setCruiseLocationId(selectedCruiseLocationIds.join(","));
    }
  }, [checked, selectedCruiseLocationIds, setCruiseLocationId]);
  /*______________________________________________*/
  // Types
  const [selectedCruiseTypeIds, setSelectedCruiseTypeIds] = useState([]); // New state for cruise types
  const [checkedTypes, setCheckedTypes] = useState(false); // New state for types checkbox

  const handleCheckboxCruiseTypeChange = (id) => {
    // New handler for cruise types
    setCheckedTypes(true);
    setSelectedCruiseTypeIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If not selected, add it
        return [...prevSelected, id];
      }
    });
  };

  useEffect(() => {
    if (checkedTypes) {
      setCruiseTypeIds(selectedCruiseTypeIds.join(","));
    }
  }, [checkedTypes, selectedCruiseTypeIds, setCruiseTypeIds]); // Add new dependencies
  /*______________________________________________*/
  // Brands
  const [selectedCruiseBrandIds, setSelectedCruiseBrandIds] = useState([]); // New state for cruise Brands
  const [checkedBrands, setCheckedBrands] = useState(false); // New state for Brands checkbox

  const handleCheckboxCruiseBrandChange = (id) => {
    // New handler for cruise Brands
    setCheckedBrands(true);
    setSelectedCruiseBrandIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If not selected, add it
        return [...prevSelected, id];
      }
    });
  };

  useEffect(() => {
    if (checkedBrands) {
      setCruiseBrandIds(selectedCruiseBrandIds.join(","));
    }
  }, [checkedBrands, selectedCruiseBrandIds, setCruiseBrandIds]);
  /*______________________________________________*/
  // Rating
  const [selectedCruiseRatingName, setSelectedCruiseRatingName] = useState([]); // New state for cruise Ratings
  const [checkedRatings, setCheckedRatings] = useState(false); // New state for Ratings checkbox

  const handleCheckboxCruiseRatingChange = (id) => {
    // New handler for cruise Ratings
    setCheckedRatings(true);
    setSelectedCruiseRatingName((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If not selected, add it
        return [...prevSelected, id];
      }
    });
  };

  useEffect(() => {
    if (checkedRatings) {
      setCruiseRatingName(selectedCruiseRatingName.join(","));
    }
  }, [checkedRatings, selectedCruiseRatingName, setCruiseRatingName]);

  const handleSearch = (e) => {
    // e.preventDefault();
    // setCruiseTypeIds('');
    // setCruiseLocationId('');
    // setCruiseBrandIds('');
    // setCruiseRatingName('');
    setSearchInput(e.target.value);
  };
  return (
    <aside className="sidebar y-gap-40">
      {/* Search  */}
      <div className="sidebar__item -no-border">
        <h5 className="text-18 fw-500 mb-10">Search... </h5>
        <div className="single-field relative d-flex items-center py-10">
          <input
            className="pl-50 border-light text-dark-1 h-50 rounded-8"
            type="email"
            placeholder="e.g. Best Western"
            onChange={handleSearch}
          />
          <button className="absolute d-flex items-center h-full" type="button">
            <i className="icon-search text-20 px-15 text-dark-1"></i>
          </button>
        </div>
      </div>
      {/* Types  */}
      <div className="sidebar__item">
        <h5 className="text-18 fw-500 mb-10"> Cruises Type </h5>
        <div className="sidebar-checkbox">
          {cruisesListBooking.filterValues?.listCruisesTypes?.map(
            (type, index) => (
              <div
                className="row y-gap-10 items-center justify-between"
                key={index}
              >
                <div className="col-auto">
                  <Checkbox
                    size="lg"
                    key={index}
                    value={type.cruiseTypeId}
                    onChange={() =>
                      handleCheckboxCruiseTypeChange(type.cruiseTypeId)
                    }
                  >
                    {type.cruiseTypeNameSys}
                  </Checkbox>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {/* Loactions  */}
      <div className="sidebar__item">
        <h5 className="text-18 fw-500 mb-10">Cruises Location </h5>
        <div className="sidebar-checkbox">
          {cruisesListBooking.filterValues?.listCruiseLocations?.map(
            (location, index) => (
              <div
                className="row y-gap-10 items-center justify-between"
                key={index}
              >
                <div className="col-auto">
                  <Checkbox
                    size="lg"
                    key={index}
                    value={location.cruiseLocationId}
                    onChange={() =>
                      handleCheckboxCruiseLocationChange(
                        location.cruiseLocationId
                      )
                    }
                  >
                    {location.cruiseLocationNameSys}
                  </Checkbox>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {/* Brands  */}
      <div className="sidebar__item">
        <h5 className="text-18 fw-500 mb-10"> Cruises Brand </h5>
        <div className="sidebar-checkbox">
          {cruisesListBooking.filterValues?.listCruisesBrands?.map(
            (brand, index) => (
              <div
                className="row y-gap-10 items-center justify-between"
                key={index}
              >
                <div className="col-auto">
                  <Checkbox
                    size="lg"
                    key={index}
                    value={brand.cruiseBrandId}
                    onChange={() =>
                      handleCheckboxCruiseBrandChange(brand.cruiseBrandId)
                    }
                  >
                    {brand.cruiseBrandNameSys}
                  </Checkbox>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {/* Rating */}
      <div className="sidebar__item">
        <h5 className="text-18 fw-500 mb-10"> Cruises Rating Star </h5>
        <div className="sidebar-checkbox">
          {cruisesListBooking.filterValues?.listRatingStar?.map(
            (rate, index) => (
              <div
                className="row y-gap-10 items-center justify-between"
                key={index}
              >
                <div className="col-auto">
                  <Checkbox
                    size="lg"
                    key={index}
                    value={rate.ratingStarName}
                    onChange={() =>
                      handleCheckboxCruiseRatingChange(rate.ratingStarName)
                    }
                  >
                    <span
                      className="pe-2"
                      dangerouslySetInnerHTML={{ __html: rate.ratingStarCode }}
                    />{" "}
                    ({rate.ratingStarName})
                  </Checkbox>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </aside>
  );
};

export default FilterLeftSide;
