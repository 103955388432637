import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  extras: [],
  selectedExtras: [],
  loadingExtras: false,
  error: null,
};

export const fetchCruiseExtras = createAsyncThunk(
  "extras/fetchCruiseExtras",
  async (payload) => {
    const response = await fetch(
      "https://api.nilecabins.info/be/getCruiseExtra",
      {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch extras");
    }
    return await response.json();
  }
);

const extrasSlice = createSlice({
  name: "extras",
  initialState,
  reducers: {
    selectExtra: (state, action) => {
      const selected = state.extras.response?.cruiseExtras?.find(
        (extra) => extra.cruiseExtraID === action.payload.cruiseExtraID
      );

      if (selected) {
        if (action.payload.checked) {
          // Add to selectedExtra array if not already present
          if (
            !state.selectedExtras.some(
              (extra) => extra.cruiseExtraID === selected.cruiseExtraID
            )
          ) {
            state.selectedExtras.push(selected);
          }
        } else {
          // Remove from selectedExtras array if present
          state.selectedExtras = state.selectedExtras.filter(
            (extra) => extra.cruiseExtraID !== selected.cruiseExtraID
          );
        }
      }
    },
    removeExtra: (state, action) => {
      // Remove the extra from selectedExtras array
      state.selectedExtras = state.selectedExtras.filter(
        (extra) => extra.cruiseExtraID !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseExtras.pending, (state) => {
        state.loadingExtras = true;
      })
      .addCase(fetchCruiseExtras.fulfilled, (state, action) => {
        state.loadingExtras = false;
        state.extras = action.payload;
      })
      .addCase(fetchCruiseExtras.rejected, (state, action) => {
        state.loadingExtras = false;
        state.error = action.error.message;
      });
  },
});

export const { selectExtra, removeExtra } = extrasSlice.actions;
export default extrasSlice.reducer;
