import React, { Fragment, useCallback, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker, SelectPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { colors, Slider, Tooltip, useMediaQuery } from "@mui/material";
// import useGoogleMapsScript from "../components/useGoogleMapsScript";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faEnvelope,
  faLocationDot,
  faMaximize,
  faMinus,
  faPhone,
  faSquarePlus,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";

import { fetchCheckOut } from "../../data/groupBooking/checkOutSlice";
import { fetchConfirmationBooking } from "../../data/groupBooking/confirmationBookingSlice";

const Checkout = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const durationID = queryParams.get("durationID");
  const opreationDate = queryParams.get("opreationDate");
  const numberRooms = queryParams.get("numberRooms");
  const adultNumber = queryParams.get("adultNumber");
  const childNumber = queryParams.get("childNumber");
  const childAgesParam = queryParams.get("childAges");
  const operationID = queryParams.get("operationID");
  const cruiseID = queryParams.get("cruiseID");
  const cbinID = queryParams.get("cbinID");
  const cruiseRateId = queryParams.get("cruiseRateId");
  const extrasIdsParam = queryParams.get("extrasIds");

  const { checkOut, loading, error } = useSelector((state) => state.checkOut);
  const { confirmationBooking } = useSelector(
    (state) => state.confirmationBooking
  );

  useEffect(() => {
    const childAges = childAgesParam
      ? childAgesParam.split(",").map(Number)
      : [];
    const extrasIds = extrasIdsParam
      ? extrasIdsParam.split(",").map(Number)
      : [];
    const payload = {
      durationID: durationID,
      opreationDate: opreationDate,
      numberRooms,
      operatinID: operationID,
      cruiseID,
      cbinID,
      cruiseRateId,
      listPassengersRoomsRequests: [
        {
          adultNumber,
          childNumber,
          childAges,
        },
      ],
      extrasIds,
    };
    if (!checkOut?.response) {
      dispatch(fetchCheckOut(payload))
        .unwrap()
        .catch((error) => {
          console.error("Failed to refetch cruise list:", error);
        });
    }
  }, [
    adultNumber,
    childAgesParam,
    childNumber,
    checkOut?.response,
    dispatch,
    durationID,
    numberRooms,
    operationID,
    opreationDate,
    cruiseID,
    cbinID,
    cruiseRateId,
    extrasIdsParam,
  ]);

  // Log the entire nationalities array to inspect its structure
  console.log(checkOut.nationalities);

  // Map through the nationalities to extract the IDs
  const nationalityData = checkOut.nationalities || [];
  const data =
    nationalityData.map((item, index) => ({
      label: item.nationalityName, // Ensure this property exists
      value: item.nationalityId, // Check if this property exists
    })) || [];

  // Log the resulting data to see if values are populated correctly
  console.log(data);
  const [terms, setTerms] = useState(false);
  const validationSchema = Yup.object().shape({
    customerFirstName: Yup.string().required("First Name is required"),
    customerLastName: Yup.string().required("Last Name is required"),
    customerEmailAddress: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    customerMobile: Yup.string().required("Phone Number is required"),
    nationalityID: Yup.number().required("Nationality is required"),
    special_Requirement: Yup.string(),
    // terms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });
  const handleCheckoutClick = useCallback(
    async (values) => {
      if (checkOut) {
        const payload = {
          request: {
            durationID: checkOut?.request?.durationID,
            opreationDate: checkOut?.request?.opreationDate,
            numberRooms: checkOut?.request?.numberRooms,
            listPassengersRoomsRequests: [
              {
                adultNumber:
                  checkOut?.request?.listPassengersRoomsRequests[0]
                    ?.adultNumber,
                childNumber:
                  checkOut?.request?.listPassengersRoomsRequests[0]
                    ?.childNumber,
                childAges:
                  checkOut?.request?.listPassengersRoomsRequests[0]
                    ?.childAges || 0,
              },
            ],

            operatinID: checkOut?.request?.operatinID,
            cruiseID: checkOut?.request?.cruiseID,
            cbinID: checkOut.request?.cbinID,
            cruiseRateId: checkOut.request?.cruiseRateId,
            extrasIds: checkOut.request?.extrasIds,
          },
          userData: {
            ...values, // Include form values in the payload
          },
        };

        // const queryString = new URLSearchParams({
        //   operationID: payload.operatinID,
        //   durationID: payload.durationID,
        //   opreationDate: payload.opreationDate,
        //   numberRooms: payload.numberRooms,
        //   adultNumber: payload.listPassengersRoomsRequests[0].adultNumber,
        //   childNumber: payload.listPassengersRoomsRequests[0].childNumber,
        //   childAges: payload.listPassengersRoomsRequests[0].childAges.join(","),
        //   extrasIds: payload.extrasIds.join(","),
        //   cruiseID: payload.cruiseID,
        //   cbinID: payload.cbinID,
        //   cruiseRateId: payload.cruiseRateId,
        // }).toString();

        try {
          const resultAction = await dispatch(
            fetchConfirmationBooking(payload)
          );
          if (fetchConfirmationBooking.fulfilled.match(resultAction)) {
            console.log("Confirmation Booking Response:", resultAction.payload); // Log the entire response
            if (resultAction.payload?.orderConfirmationNumber) {
              localStorage.setItem(
                "orderConfirmationNumber",
                resultAction.payload.orderConfirmationNumber
              );

              const kashierUrl = resultAction.payload.kashierUrl; // Use the payload directly
              if (kashierUrl) {
                console.log("Redirecting to:", kashierUrl); // Debugging log
                window.open(kashierUrl, "_blank");
              } else {
                console.error("kashierUrl is not available."); // Debugging log
                alert("Unable to redirect. Kashier URL is not available.");
              }
            }
          } else {
            throw new Error(resultAction.error.message);
          }
        } catch (err) {
          alert(err.message || "An error occurred while searching");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkOut,
      dispatch,
      confirmationBooking?.kashierUrl,
      confirmationBooking?.orderConfirmationNumber,
    ]
  );
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          {" "}
          <Helmet>
            <title>Checkout</title>{" "}
            <style>
              {`
            .header .header-menu .menu li a{
            color:#051036;
            }
            .header .header-menu .menu .subnav>li>a {
            color:#051036 !important;
            }
            .is-sticky.bg-dark-1{
            background-color:#fff !important;
            }
            // .is-sticky .menu li a{
            // color:#fff !important;
            // }
            .ratio-15\:9::before{
            padding-bottom:0 !important;}
            `}
            </style>
          </Helmet>
          {/*banner */}
          <section className="row d-flex items-center  mt-90  ">
            <div className="  mt-40 py-40 bg-light-2 border-top-light">
              <div className="container d-flex justify-content-between m-auto px-1    y-gap-30 items-center ">
                <div className="col-auto pe-1">
                  <Tooltip arrow title="Return To Choose Cruise">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-3)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cruise
                      </div>
                    </div>
                  </Tooltip>
                </div>{" "}
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <div className="col-auto  px-1">
                  <Tooltip arrow title="Return To Choose Cabin">
                    <div
                      className="d-flex items-center"
                      onClick={() => navigate(-2)}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#1976d2";
                      }}
                    >
                      <div className="size-40 rounded-full flex-center bg-blue-1">
                        <i className="icon-check text-16 text-white"></i>
                      </div>
                      <div className="text-18 text-blue-1 fw-500 ml-10">
                        Choose Cabin
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 border-blue-1 bg-border"></div>
                </div>
                <Fragment>
                  <div className="col-auto  px-1">
                    <Tooltip arrow title="Return To Select Extras">
                      <div
                        className="d-flex items-center"
                        onClick={() => navigate(-1)}
                        style={{ cursor: "pointer" }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "#1976d2";
                        }}
                      >
                        <div className="size-40 rounded-full flex-center bg-blue-1">
                          <i className="icon-check text-16 text-white"></i>
                        </div>
                        <div className="text-18 text-blue-1 fw-500 ml-10">
                          Extras selection
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col px-1">
                    <div className="w-full h-1 border-blue-1 bg-border"></div>
                  </div>
                </Fragment>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div className="size-40 rounded-full flex-center bg-blue-1">
                      <span className=" text-16 text-white">4</span>
                    </div>
                    <div className="text-18 fw-500 ml-10">Checkout </div>
                  </div>
                </div>
                <div className="col px-1">
                  <div className="w-full h-1 bg-border"></div>
                </div>
                <div className="col-auto px-1">
                  <div className="d-flex items-center">
                    <div class="size-40 rounded-full flex-center bg-blue-1-05 text-blue-1 fw-500">
                      5
                    </div>
                    <div className="text-18 fw-500 ml-10">
                      Booking Completed!{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-40 layout-pb-md">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-7 col-lg-8">
                  <h2 className="text-22 fw-500  ">
                    Your Personal Information
                  </h2>

                  <Formik
                    initialValues={{
                      customerFirstName: "",
                      customerLastName: "",
                      customerEmailAddress: "",
                      customerMobile: "",
                      nationalityID: null,
                      special_Requirement: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleCheckoutClick(values);
                    }}
                  >
                    {(values) => (
                      <Form>
                        <div className="row x-gap-20 y-gap-20 pt-20">
                          <div className="col-md-6">
                            <div className="form-input">
                              <Field
                                name="customerFirstName"
                                type="text"
                                className="form-control"
                                required
                              />
                              <label className="lh-1 text-16 text-light-1">
                                First Name
                              </label>
                            </div>
                            <ErrorMessage
                              name="customerFirstName"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-input">
                              <Field
                                name="customerLastName"
                                type="text"
                                className="form-control"
                                required
                              />
                              <label className="lh-1 text-16 text-light-1">
                                Last Name
                              </label>
                            </div>
                            <ErrorMessage
                              name="customerLastName"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-input">
                              <Field
                                name="customerEmailAddress"
                                type="text"
                                required
                              />
                              <label className="lh-1 text-16 text-light-1">
                                Email
                              </label>
                            </div>
                            <ErrorMessage
                              name="customerEmailAddress"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-input">
                              <Field
                                name="customerMobile"
                                type="text"
                                required
                              />
                              <label className="lh-1 text-16 text-light-1">
                                Phone Number
                              </label>
                            </div>
                            <ErrorMessage
                              name="customerMobile"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="form-input ">
                              <Field
                                as={SelectPicker}
                                name="nationalityID"
                                data={data} // Ensure data is not empty
                                size="lg"
                                placeholder="Select Nationality"
                                onChange={(value) => {
                                  values.setFieldValue("nationalityID", value);
                                }}
                              />
                            </div>{" "}
                            <ErrorMessage
                              name="nationalityID"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                          </div>
                          <div className="col-12">
                            <div className="form-input">
                              <Field
                                name="special_Requirement"
                                as="textarea"
                                rows="6"
                              />
                              <label className="lh-1 text-16 text-light-1">
                                Special Requests
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-1 bg-border mt-40 mb-40"></div>
                        <div className="col-12">
                          <div className="row y-gap-20 items-center justify-between">
                            <div className="form-checkbox d-flex align-items-center">
                              <Field
                                type="checkbox"
                                checked={terms}
                                value={terms}
                                onChange={(e) => setTerms(e.target.checked)}
                              />
                              <div className="form-checkbox__mark">
                                <div className="form-checkbox__icon icon-check"></div>
                              </div>
                              <div className="col-auto ms-2">
                                <label
                                  htmlFor="terms"
                                  className="text-14 text-light-1"
                                >
                                  By proceeding with this booking, I agree to
                                  Booking Nile{" "}
                                  <Link to="#.">Terms & Conditions</Link>.
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              name="terms"
                              component="div"
                              className="error text-danger text-red-1 d-block text-center"
                            />
                            <div className="col-auto">
                              <button
                                type="submit"
                                className="button h-60 px-24 -dark-1 bg-blue-1 text-white"
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="">
                    {/* Extras  */}

                    <div className="px-30 py-30 mt-20 border-light rounded-4">
                      <div className="row align-items-center x-gap-15 y-gap-20">
                        <div className="text-20 fw-500  mb-20">
                          Your booking details{" "}
                        </div>
                        <div className="col-auto">
                          <img
                            src={checkOut.response?.cruisePhoto}
                            alt={checkOut.response?.cruiseName}
                            loading="lazy"
                            className="size-120 rounded-4 object-cover"
                          />
                        </div>

                        <div className="col">
                          <div
                            className="d-flex x-gap-5 pb-10"
                            dangerouslySetInnerHTML={{
                              __html: checkOut.response?.curiseStarCode,
                            }}
                          />

                          <div className="text-20 lh-17 fw-500">
                            {checkOut.response?.cruiseName}{" "}
                          </div>
                          <div className="text-14 lh-15 mt-5">
                            {checkOut.response?.itineraryName}{" "}
                          </div>
                        </div>
                      </div>

                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="row y-gap-20 justify-between">
                        <div className="col-auto">
                          <div className="text-15">Check-in </div>
                          <div className="fw-500">
                            {checkOut.response?.itineraryFrom}
                          </div>
                        </div>

                        <div className="col-auto md:d-none">
                          <div className="h-full w-1 bg-border"></div>
                        </div>

                        <div className="col-auto text-right md:text-left">
                          <div className="text-15">Check-out </div>
                          <div className="fw-500">
                            {checkOut.response?.itineraryTo}
                          </div>
                        </div>
                      </div>
                      <div className="border-top-light mt-30 mb-20"></div>

                      <div className="text-15 mb-10">You selected: </div>
                      <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto d-flex">
                          <img
                            style={{ borderRadius: "5px" }}
                            src={checkOut.response?.cabinData?.cabinPhoto}
                            alt=""
                            className="me-2"
                            width="50"
                          />{" "}
                          <div className="text-main fw-500 text-16">
                            {checkOut.response?.cabinData?.cabinName}
                            <br />
                            <span className="text-12 text-secondary">
                              {" "}
                              ({checkOut.response?.cabinData?.cabinTypeNameSys})
                            </span>
                          </div>
                        </div>

                        <div className="col-auto d-flex flex-column align-items-end justify-content-start">
                          <div className="col-auto d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({checkOut.response?.cabinData?.cabinBed}){" "}
                            </div>

                            <FontAwesomeIcon
                              icon={faBed}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                          <div className="col-auto  d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({checkOut.response?.cabinData?.cabinOccupancy}){" "}
                            </div>
                            <FontAwesomeIcon
                              icon={faUsersViewfinder}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                          <div className="col-auto  d-flex align-items-center">
                            <div className="text-12 text-secondary">
                              ({checkOut.response?.cabinData?.cabinSize}
                              {
                                checkOut.response?.cabinData?.sizeMeasurement
                              }){" "}
                            </div>
                            <FontAwesomeIcon
                              icon={faMaximize}
                              className="text-12 text-main ps-1"
                            />
                          </div>
                        </div>
                      </div>
                      {checkOut?.response?.extraDetails.cruiseExtras.length >
                      0 ? (
                        <Fragment>
                          <div className="border-top-light mt-30 mb-20"></div>
                          <div className="row y-gap-20 justify-between items-center">
                            <h5 className="text-15">Extras: </h5>
                            <ul id="included" className="ps-4">
                              {checkOut.response.extraDetails?.cruiseExtras?.map(
                                (extra, index) => (
                                  <li
                                    key={extra.cruiseExtraID}
                                    className={`row d-flex justify-content-between align-items-center py-2 ps-1  `}
                                  >
                                    <p className="d-flex align-items-center me-2 col-lg-8">
                                      {/* <FontAwesomeIcon
                                      icon={faSquarePlus}
                                      className="text-13 text-main pe-1"
                                    /> */}

                                      <h6 className="form-select  text-14 text-main fw-400">
                                        {extra.cruiseExtraName}
                                      </h6>
                                    </p>
                                    <p className="me-2 fw-bold col-lg-3 text-center  text-primary">
                                      {extra.currencyCode}
                                      {extra.cruiseExtraTotalPrice}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                            <div className="col-auto mx-auto py-0">
                              <div className="text-14">
                                {
                                  checkOut.response.extraDetails?.cruiseExtras
                                    ?.length
                                }{" "}
                                Extra
                                {`${
                                  checkOut.response.extraDetails?.cruiseExtras
                                    ?.length > 1
                                    ? "s"
                                    : ""
                                }`}{" "}
                                , (
                                {
                                  checkOut.response.extraDetails
                                    ?.extraTotalPrice
                                }
                                $)
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => navigate(-1)}
                              className="text-15 text-blue-1 underline"
                            >
                              Change your Extra selection{" "}
                            </button>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>
                    <div className="px-30 py-30 border-light rounded-4 mt-30">
                      <div className="text-20 fw-500 mb-15">
                        Do you have a promo code?{" "}
                      </div>

                      <div className="form-input ">
                        <input type="text" required="" />
                        <label className="lh-1 text-16 text-light-1">
                          Enter promo code{" "}
                        </label>
                      </div>

                      <button className="button -outline-blue-1 text-blue-1 px-30 py-15 mt-20">
                        Apply{" "}
                      </button>
                    </div>
                    <div className="px-30 py-30 border-light rounded-4 mt-30">
                      <div className="text-20 fw-500 mb-20">
                        Your price summary{" "}
                      </div>
                      {checkOut.response?.taxes?.taxs?.map((item, index) => (
                        <div
                          key={index}
                          className="row y-gap-5 justify-between"
                        >
                          <div className="col-auto">
                            <div className="text-15">{item.taxName} </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-15"> ${item.taxValue} </div>
                          </div>
                        </div>
                      ))}

                      <div className="px-20 py-20 bg-blue-2 rounded-4 mt-20">
                        <div className="row y-gap-5 justify-between">
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">Price </div>
                          </div>
                          <div className="col-auto">
                            <div className="text-18 lh-13 fw-500">
                              ${checkOut.response?.totalPrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </section>
        </>
      )}{" "}
    </Fragment>
  );
};

export default Checkout;
