import React from "react";

const BookingRightSideDetails = ({ cruiseDetails }) => {
  return (
    <div className="d-flex justify-end js-pin-content">
      <div className="w-360 lg:w-full d-flex flex-column items-center">
        <div className="px-30 py-30 rounded-4 border-light bg-white shadow-4">
          <div className="text-14 text-light-1">
            <span className="text-20 fw-500 ml-5">
              {cruiseDetails?.result_Cruise?.itineraryName}
            </span>
          </div>

          <div className="row y-gap-20 pt-30">
            <div className="col-12">
              <div className="searchMenu-date px-20 py-10 border-light rounded-4 -right js-form-dd js-calendar js-calendar-el">
                <div data-x-dd-click="searchMenu-date">
                  <h4 className="text-15 fw-500 ls-2 lh-16">Date </h4>

                  <div className="capitalize text-15 text-light-1 ls-2 lh-16">
                    <span className="js-first-date">
                      {cruiseDetails?.request?.opreationDate}
                    </span>
                  </div>
                </div>

                <div
                  className="searchMenu-date__field shadow-2"
                  data-x-dd="searchMenu-date"
                  data-x-dd-toggle="-is-active"
                >
                  <div className="bg-white px-30 py-30 rounded-4">
                    <div className="elCalendar js-calendar-el-calendar"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="searchMenu-guests px-20 py-10 border-light rounded-4 js-form-dd js-form-counters">
                <div data-x-dd-click="searchMenu-guests">
                  <h4 className="text-15 fw-500 ls-2 lh-16">
                    Number of travelers{" "}
                  </h4>

                  <div className="text-15 text-light-1 ls-2 lh-16">
                    {
                      cruiseDetails?.request?.listPassengersRoomsRequests[0]
                        .adultNumber
                    }{" "}
                    Adult
                    {cruiseDetails?.request?.listPassengersRoomsRequests[0]
                      .adultNumber > 1
                      ? "s"
                      : null}{" "}
                    &{" "}
                    {
                      cruiseDetails?.request?.listPassengersRoomsRequests[0]
                        .childNumber
                    }{" "}
                    Child
                    {cruiseDetails?.request?.listPassengersRoomsRequests[0]
                      .childNumber > 1
                      ? "s"
                      : null}{" "}
                    ({cruiseDetails?.request?.numberRooms} Room
                    {cruiseDetails?.request?.numberRooms > 1 ? "s" : null})
                  </div>
                </div>

                <div
                  className="searchMenu-guests__field shadow-2"
                  data-x-dd="searchMenu-guests"
                  data-x-dd-toggle="-is-active"
                >
                  <div className="bg-white px-30 py-30 rounded-4">
                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 fw-500">Adults </div>
                      </div>

                      <div className="col-auto">
                        <div
                          className="d-flex items-center js-counter"
                          data-value-change=".js-count-adult"
                        >
                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15 js-count">2 </div>
                          </div>

                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="border-top-light mt-24 mb-24"></div>

                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 lh-12 fw-500">Children </div>
                        <div className="text-14 lh-12 text-light-1 mt-5">
                          Ages 0 - 17{" "}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div
                          className="d-flex items-center js-counter"
                          data-value-change=".js-count-child"
                        >
                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15 js-count">1 </div>
                          </div>

                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="border-top-light mt-24 mb-24"></div>

                    <div className="row y-gap-10 justify-between items-center">
                      <div className="col-auto">
                        <div className="text-15 fw-500">Rooms </div>
                      </div>

                      <div className="col-auto">
                        <div
                          className="d-flex items-center js-counter"
                          data-value-change=".js-count-room"
                        >
                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down">
                            <i className="icon-minus text-12"></i>
                          </button>

                          <div className="flex-center size-20 ml-15 mr-15">
                            <div className="text-15 js-count">1 </div>
                          </div>

                          <button className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up">
                            <i className="icon-plus text-12"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12">
                     <button className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white">
                       ____ Now
                     </button>
                   </div> */}
          </div>

          <div className="d-flex items-center pt-20">
            <div className="size-40 flex-center bg-light-2 rounded-full">
              <i className="icon-heart text-16 text-green-2"></i>
            </div>
            <div className="text-14 lh-16 ml-10">
              94% of travelers recommend ____ experience{" "}
            </div>
          </div>
        </div>

        <div className="px-30">
          <div className="text-14 text-light-1 mt-30">
            Not sure? You can ______ this reservation up to 24 hours in advance
            for _ full refund.{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingRightSideDetails;
