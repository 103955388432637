import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Counter from "../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

const Offers = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  return (
    <>
      {" "}
      <Helmet>
        <title>Special Offers</title>
        <style>
          {`.layout-pt-lg{
			padding-top: 200px;
				padding-bottom: 150px;
	  }
	  `}
        </style>
      </Helmet>
      {/*banner */}
      <section
        className="section-bg layout-pt-lg layout-pb-lg"
        style={{
          backgroundImage: "url(/TITSolutions/img/new/bg/bg-1.jpg)",
          backgroundAttachment: "fixed",
          backgroundSize: isMobile ? "cover" : "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <div className="section-bg__item overlay col-12">
          <img src="/TITSolutions/img/new/bg/bg-1.jpg" alt="banner" />
        </div> */}

        <div className="container">
          <div className="row justify-left text-start">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <h1 className="text-40 md:text-25 fw-600 text-white py-30">
                Special Offers{" "}
              </h1>
              {/* <div className="col-lg-3 mt-3">
                <Link
                  to="/become-partner"
                  className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                >
                  Join Us <div className="icon-arrow-top-right ps-2"></div>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex items-center">
        <div className="container   py-20 border-bottom-light">
          <div className="row y-gap-10 items-center justify-between">
            <div className="col-auto">
              <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div className="col-auto">
                  <div className="">
                    <Link to="/">Home</Link>{" "}
                  </div>
                </div>
                <div className="col-auto">
                  <div>
                    <FontAwesomeIcon
                      className="text-10 text-light-1"
                      icon={faAngleRight}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="">Special Offers </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              Quick Contact:{" "}
              <Link to="tel:+201000972210" className="text-14 text-primary">
                +(20) 10 00972210{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>{" "}
      <section className="pt-40 layout-pb-lg">
        <div className="container">
          <div className="row y-gap-20 justify-between items-center">
            <div className="col-auto">
              <div className="row text-center x-gap-20 y-gap-10 items-center">
                <div className="sectionTitle -md col-lg-9 mx-auto  ">
                  <img
                    src="cruise-icon.jpg"
                    alt=""
                    width="80"
                    className="me-2"
                    style={{ filter: "hue-rotate(375deg)" }}
                  />
                  <h2 className="sectionTitle__title mb-3 ">
                    {" "}
                    We Have Special Offers And Discounts For Special Cruises
                  </h2>
                  <p>
                    Discover the enchanting beauty of the Nile with our
                    exclusive cruise packages. Enjoy breathtaking views,
                    luxurious accommodations, and exceptional service as you
                    explore the rich history and culture along the river. Book
                    your Nile cruise today and take advantage of our special
                    offers to create unforgettable memories!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top-light mt-30 mb-30"></div>

          <div className="row y-gap-30">
            <div className="col-lg-4 col-sm-6">
              <Link to="#." className="hotelsCard -type-1 ">
                <div className="hotelsCard__image">
                  <div className="cardImage ratio ratio-1:1 rounded-4">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/TITSolutions/img/new/wedding/1.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="hotelsCard__content mt-10">
                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500 ">
                    <span>Wedding Cruises </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Cruise weddings: The perfect way to get married!
                  </p>

                  <div className="d-flex items-center mt-20">
                    <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div className="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div className="text-14 text-light-1 ml-10">
                      3,014 reviews{" "}
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="fw-500">
                      Starting from <span className="text-blue-1">$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-sm-6">
              <Link to="#." className="hotelsCard -type-1 ">
                <div className="hotelsCard__image">
                  <div className="cardImage ratio ratio-1:1 rounded-4">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/TITSolutions/img/new/couples/1.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="hotelsCard__content mt-10">
                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Honeymoon Cruises </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Experience an unforgettable honeymoon cruise.
                  </p>

                  <div className="d-flex items-center mt-20">
                    <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div className="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div className="text-14 text-light-1 ml-10">
                      3,014 reviews{" "}
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="fw-500">
                      Starting from <span className="text-blue-1">$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="#." className="hotelsCard -type-1 ">
                <div className="hotelsCard__image">
                  <div className="cardImage ratio ratio-1:1 rounded-4">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/TITSolutions/img/new/couples/10.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="hotelsCard__content mt-10">
                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Couple Cruises </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Book Cruises for couples that are the most romantic.
                  </p>

                  <div className="d-flex items-center mt-20">
                    <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div className="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div className="text-14 text-light-1 ml-10">
                      3,014 reviews{" "}
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="fw-500">
                      Starting from <span className="text-blue-1">$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="#." className="hotelsCard -type-1 ">
                <div className="hotelsCard__image">
                  <div className="cardImage ratio ratio-1:1 rounded-4">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/TITSolutions/img/new/family/1.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="hotelsCard__content mt-10">
                  <h4 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>Family Cruises </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5">
                    Cruises for Families: The Ultimate Family Cruise Vacation
                  </p>

                  <div className="d-flex items-center mt-20">
                    <div className="flex-center bg-blue-1 rounded-4 size-30 text-12 fw-600 text-white">
                      4.8{" "}
                    </div>
                    <div className="text-14 text-dark-1 fw-500 ml-10">
                      Exceptional{" "}
                    </div>
                    <div className="text-14 text-light-1 ml-10">
                      3,014 reviews{" "}
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="fw-500">
                      Starting from <span className="text-blue-1">$72 </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offers;
