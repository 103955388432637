import React from "react";
import "./loader.css";

const LoaderInternal = () => {
  return (
    <div className="loader-internal-container flex-column">
      <img
        src="/ship-wheel.svg"
        alt="Loading..."
        className="spinning-wheel d-block "
      />
      <h5 className="text-main d-block my-5">
        Loading All Available Cruises Matches Your Search...
      </h5>
      {/* <p className="text-secondary  d-block">Loading...</p> */}
    </div>
  );
};

export default LoaderInternal;
